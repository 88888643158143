import {
  getStarList,
  addStar,
  modifyStar,
  delStar
} from "@/service/costSet/index";
export default {
  //新增订单
  name: "starFee",
  data() {
    return {
      //编辑页面数据
      userId:sessionStorage.getItem('userId'),
      starFee: {
        list: {
          query: {
            unitId:sessionStorage.getItem('unitId'),
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: []
        },
        modifyTrain: false,
        modalTitle: '',
        optionItem: '',
        starId: '',
        oldName: '',
        // 去重暂存数据集
        newArr: []
      },
      basicForm: this.$form.createForm(this),
      //表头
      columns: [{
          width: 75,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        }, {
          title: "星级名称",
          dataIndex: "starName",
        },
        {
          title: "星级类型",
          dataIndex: "starType",
          customRender: (item) => {
            return this.$enums.starType[item];
          }
        },
        {
          title: "星级价格",
          dataIndex: "price",
          customRender: (item) => {
            return item / 100;
          }
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['星级费用'],
      selName:[],
      excelData:[],
    };
  },
  methods: {
    // 查询列表
    queryStar(queryBy) {
      let querys = this.starFee.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getStarList(querys).then((res) => {
        this.resetExport();
        this.starFee.list.results = [...res.data];
        for(let i=0;i<this.columns.length-1;i++){
          this.selName.push(" ")
        }
        let excelsource = this.comexport(this.columns, this.starFee.list.results);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$store.dispatch("StoreDataQuery/setQueryByStarCost",querys);
      })
    },
    getDate(){
          this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
    },
    confirmRevision() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          values.price = values.price * 100;
          values.starId = this.starFee.starId;
          values.unitId = this.starFee.list.query.unitId;
          this.saveDate(values);

        }
      });
    },
    saveDate(values) {
      const newArr=this.isdouble(values);
      if (this.starFee.optionItem === 'addData') {
        if (newArr.includes(true)) {
          this.$message.info('此数据已存在,请重新输入');
          return;
        } 
        values.status = 0;
        addStar(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('新增成功');
          }
        })
      } else {
        modifyStar(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('修改成功');
          }
        })
      }
    },
    modifyData(record) {
      this.starFee.starId = record.starId;
      this.starFee.modifyTrain = true;
      this.starFee.oldName = record.starName;
      if (record) {
        this.starFee.modalTitle = '修改星级费用数据'
        this.starFee.optionItem = 'modifyData';
        this.$nextTick(() => {
          this.basicForm.setFieldsValue({
            starName: record.starName,
            starType: record.starType,
            price: record.price/100,
            status: record.status,
          });
        })
      } else {
        this.starFee.modalTitle = '增加星级费用数据'
        this.starFee.optionItem = 'addData';
      }
    },
    // 删除
    delData(record) {
      const {
        starId,
        unitId
      } = record;
      let params = {
        starId,
        unitId
      }
      delStar(params).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.resetInfo();
          this.$message.success('删除成功');
        }
      })
    },
      isdouble(values){
          const onceArr = this.starFee.list.results
          this.starFee.newArr = onceArr;
          const newArr = this.starFee.newArr.map(item => {
              return item.starName === values.starName&&item.starType===values.starType
          })
          return newArr;
      },
    resetInfo() {
      this.queryStar();
      this.starFee.modifyTrain = false;
      this.handleFormFieldsReset(this.basicForm);
    },
    resetExport(){
      this.selName=[];
      this.excelData=[];
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.queryStar();
     this.isFav();
  },
  activated(){
     this.isFav();
  }
};