import {
  applyFax,
  changeFax,
  getListByFax,
  getListCountByFax
} from "@/service/charge/index";
import {
  getFaxInfo
} from "@/service/dev/index";
import {
  openDownLoad
} from "@/utils/index";
import { init } from "echarts";
export default {
  //新增订单
  name: "invoicePrint",
  data() {
    return {
      billingDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            orderDate: this.$moment().format('YYYYMMDD'),
            orderDateEnd: this.$moment().format('YYYYMMDD'),
            stage: 0,
            unitId: sessionStorage.getItem('unitId'),
            status: -1,
            stageMark: 0,
            fax: ' ',
            faxType: ''
          },
          total: 0,
          results: [],
        },
        daterange: [this.$moment(),this.$moment()],
        stuCid: '',
        apply: false,
        radResult: [],
        selectedInfo: [],
      },
      basicForm: this.$form.createForm(this),
      modyInvoceForm: this.$form.createForm(this),
      //表头
      columns: [
      {
          title: "业务编号",
          dataIndex: "stageMark",
        }, {
          title: "发票类型",
          dataIndex: "faxType",
          customRender: (item) => {
            return item == 'c' ? '普票' :item == 't'? '电子票':''
          }
        }, {
          title: "发票号",
          dataIndex: "fax",
          scopedSlots: {
            customRender: "fax"
          }
        }, {
          title: "含税金额",
          dataIndex: "cost",
          customRender: (item) => {
            return `${item / 100}`
          }
        },
        {
          title: "税率",
          dataIndex: "faxRatio",
          customRender: (item) => {
            if (item) {
              return `${item}%`
            }
          }
        },
        {
          title: "税额",
          dataIndex: "faxCost",
        },
        {
          title: "业务日期",
          dataIndex: "orderDate",
        },
        {
          title: "车号",
          dataIndex: "vehno",
          customRender: (item) => {
            return `${item}号`
          }
        },
        {
          title: "科目",
          dataIndex: "stage",
          customRender: (item) => {
            return `${item}`
          }
        }, {
          title: "业务状态",
          dataIndex: "status",
          customRender: (item) => {
            return this.$enums.stageStatus[item];
          }
        }, {
          title: "购买时长",
          dataIndex: "costTime",
          customRender: (item) => {
            return `${item / 60} 小时`
          }
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }],
      modal: {
        // 打印发票
        printInvoice: {
          visible: false,
          data: []
        },
        // 发票类别
        plainOptions: [
          { label: '普票', value: 'c' },
          { label: '电子发票', value: 't' },
        ],
        // 变更票号
        modifyInvoice: {
          visible: false,
          data: {}
        },
      },
      oldMsg: {},
      initRatio:0,
      faxDataSource:[],
      faxDataSn:[],
    };
  },
  methods: {
    // 查询默认税率
    async  queryRatio() {
      let query = { unitId: sessionStorage.getItem('unitId') }
      await getFaxInfo(query).then(res => {
        this.initRatio=res.data.faxRatio?res.data.faxRatio:0;
      })
    },
    // 查询列表
    async queryBilling(queryBy) {
      let querys = this.billingDetails.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      await   getListCountByFax(querys).then((res) => {
        this.billingDetails.list.total = res.data.rowCount;
        if (res.data.rowCount) {
          getListByFax(querys).then((res) => {
            this.$nextTick(() => {
              this.billingDetails.list.results = res.data;
            });
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          this.$nextTick(() => {
            this.billingDetails.list.results = [];
            this.billingDetails.list.total = 0;
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 业务编号查询
    onSearch(value) {
      this.billingDetails.list.query.stageMark = value.target.value ? value.target.value : 0;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      const stageMark = "stageMark";
      // key 序号
      //selectedInfo 选择的行
      this.billingDetails.radResult = [...selectedRowKeys];
      selectedRows.map(item => {
        this.billingDetails.selectedInfo.push(item);
      });
      const r = this.billingDetails.selectedInfo.reduce((all, next) => all.some((atom) => atom[stageMark] == next[stageMark]) ? all : [...all, next], []);
      this.billingDetails.selectedInfo = r.filter(item => {
        return this.billingDetails.radResult.some((e) => {
          return item.stageMark === e;
        });
      });
    },
    // 单个打印
    print(record) {
      if (record.length === 1) {
        setTimeout(() => {
          this.basicForm.setFieldsValue({
            stageMark: record[0].stageMark,
            cost: record[0].cost / 100,
            faxType: 'c'
          });
        }, 0)
      } else {
        setTimeout(() => {
          this.basicForm.setFieldsValue({
            faxType: 'c'
          });
        }, 0)
      }
      this.modal.printInvoice.visible = true;
      this.modal.printInvoice.data = record;
    },
    // 调用打印接口
    getInvoive() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          this.modal.printInvoice.data.map((item, index) => {
            let params = { stageMark: item.stageMark, faxType: values.faxType, userId: sessionStorage.getItem('userId'), unitId: sessionStorage.getItem('unitId'), email: values.faxType == 'c' ? '' : values.email }
            applyFax(params).then(res => {
              if (res.message) {
                if (index + 1 === this.modal.printInvoice.data.length) {
                  this.$message.error(`开票失败,原因是：${res.message}`);
                }

              } else {
                if (index + 1 === this.modal.printInvoice.data.length) {
                  this.$message.info(`开票成功`);
                }
                this.queryBilling();
                this.handleCancel();
              }
            })
          })
        }
      })
    },
    // 关闭打印
    handleCancel() {
      this.modal.printInvoice.visible = false;
      this.handleFormFieldsReset(this.basicForm);
    },
    // 变更票号
    changeInvoice(record) {
      console.log(record,this.initRatio);
      this.oldMsg = { ...record };
      this.oldMsg.faxRatio=this.oldMsg.faxRatio? this.oldMsg.faxRatio:this.initRatio;
      this.oldMsg.faxCost=this.oldMsg.faxCost? this.oldMsg.faxCost:Math.round(record.cost/100/(1+this.initRatio)*this.initRatio * 100) / 100 ;
      setTimeout(() => {
        this.modyInvoceForm.setFieldsValue({
          oldNum: record.fax,
          faxRatio: record.faxRatio ?record.faxRatio :this.initRatio * 100,
          faxCost: record.faxCost?record.faxCost:Math.round(record.cost/100/(1+this.initRatio)*this.initRatio * 100) / 100,
        });
      }, 0)
      console.log(Math.round(record.cost/100/(1+this.initRatio)*this.initRatio * 100) / 100);
      this.modal.modifyInvoice.visible = true;
      this.modal.modifyInvoice.data = record;
    },
    // 修改票号
    modifyNum() {
      this.modyInvoceForm.validateFields((err, values) => {
        if (!err) {
          let item = this.modal.modifyInvoice.data;
          let params = {stageMark: item.stageMark,faxRatio:values.faxRatio,faxCost:values.faxCost, faxType: values.faxType, faxDate: this.$moment(values.faxDate).format('YYYY-MM-DD'), faxNew: values.faxNew, userId: sessionStorage.getItem('userId'), unitId: sessionStorage.getItem('unitId') }
          changeFax(params).then(res => {
            if (res.message) {
              this.$message.error(`修改票号失败,原因是：${res.message}`);

            } else {
              this.$message.info(`修改成功`);
              this.queryBilling();
              this.modiInvoiceCancel();
            }
          })
        }
      })
    },
    // 关闭修改票号
    modiInvoiceCancel() {
      this.modal.modifyInvoice.visible = false;
      this.handleFormFieldsReset(this.modyInvoceForm);
    },
    // 查看发票
    download(link) {
      if (!link) {
        return
      }
      openDownLoad(link);
    },
    // 税率税额修改
    getNum(num, type) {
      if (type === 'faxRatio') {
        if (num / 100 !== this.oldMsg.faxRatio) {
          this.$message.info('和原始值不一致，请谨慎操作')
        }
      } else {
        if (num !== this.oldMsg.faxCost) {
          this.$message.info('和原始值不一致，请谨慎操作')
        }
      }
    },
    // // 购买方税号
    // handleChange(value){
    //   this.faxDataSource=[value];
    // },
    async  getData() {
      await this.queryRatio();
      await this.queryBilling();
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    this.getData();
  },
  activated() {
  }
};
