import {trainMonth} from "@/service/businessData/index";
export default {
    name: "shortTrainMonth",
    data() {
        return {
            shortTrainMonth: {
                list: {
                    query: {
                        begin:this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        localTags:-1,
                        stage:2,
                        cType:'-1',
                        unitId:sessionStorage.getItem('unitId'),
                        vehType:'',
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    tota: 0,
                    results:[],
                    dataa:[]
                },
                daterange: [this.$moment(),this.$moment()],
                bindResult: {
                    radResult: [],
                    selectedInfo: [],
                    columns: [{
                        width: 150,
                        title: "序号",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                        {
                            title: "车号",
                            dataIndex: "vehNo",
                            width: 150,
                            customRender: (item) => {
                                return `${item }号车`
                            },
                        },
                        {
                            title: "优惠券金额",
                            width: 150,
                            dataIndex: "CouponPrice",
                            customRender: (item) => {
                                return item ? item / 100 : 0;
                            }
                        },
                        {
                            title: "车型",
                            dataIndex: "vehType",
                            width: 150,
                        },
                        {
                            title: "科目",
                            dataIndex: "stage",
                            width: 150,
                        },
                        {
                            title: "时长(小时)",
                            dataIndex: "costTime",
                            width: 150,
                            customRender: (item) => {
                                return item / 60
                            }
                        },
                        {
                            title: "合计",
                            dataIndex: "cost",
                            width: 150,
                            customRender: (item) => {
                                return item / 100
                            }
                        },
                    ]
                }
            },
            excelName: ['短训月报'],
            cTypeClumn: [],
            columns:[],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId'),
        }
    },
    methods: {
        getCtype (e) {
            if (e == '-1') {
                this.cTypeClumn = this.shortTrainMonth.bindResult.columns.filter(e => {
                    return  e.dataIndex != "CouponPrice" && e.dataIndex != "cType"
                })
            } else if (e == '2') {
                this.cTypeClumn = [... this.shortTrainMonth.bindResult.columns]
            } else {
                this.cTypeClumn = this.shortTrainMonth.bindResult.columns.filter(e => {
                    return  e.dataIndex != "CouponPrice"
                })
            }
        },
        // 表格行颜色样式
        trColor(record, index) {
            // { 0: "正常", 1: "结算",2:'异常', 3:'注销',}
            let className = 'light-row';
            if (record.note != null) { className = 'biaoji-row' }
            return className;
        },
        getTotal(){
            let total=0;
            this.shortTrainMonth.list.results.filter(item=>{
                total=total+item.cost/100;
            })
            return total;
        },
        getTota(){
            let tota=0;
            this.shortTrainMonth.list.results.filter(item=>{
                tota=tota+item.CouponPrice/100;
            })
            return tota;
        },
        queryTrainMonth() {
            let querys = this.shortTrainMonth.list.query;
            this.shortTrainMonth.columns = [...this.cTypeClumn];
            console.log('this.shortTrainMonth.columns', this.shortTrainMonth.columns)
            trainMonth(querys).then(res =>{
                console.log('022', res)
                this.shortTrainMonth.list.dataa=[]
                this.shortTrainMonth.list.dataaa=[]
                console.log('002', this.shortTrainMonth.list.dataaa)
                this.shortTrainMonth.list.results=[...res.data]
                let arr=[...res.data]
                for(var i in arr){
                    this.shortTrainMonth.list.dataa.push(`${arr[i]['vehNo']}号车`);
                }
                for(var j in arr){
                    if (this.cTypeClumn.length ===6) {
                        this.shortTrainMonth.list.dataaa.push(arr[j]['cost']/100);
                    } else {
                        this.shortTrainMonth.list.dataaa.push(arr[j]['CouponPrice']/100);
                    }
                }
                this.drawLine();
                this.resetExport();
                this.selName = [`查询日期: ${this.shortTrainMonth.list.query.begin}-${this.shortTrainMonth.list.query.end}`,`业务类型: ${this.$rformat.methods.r_cType(this.shortTrainMonth.list.query.cType)}`,`归属: ${this.$rformat.methods.r_isSch(this.shortTrainMonth.list.query.localTags)}`, `车型：${this.$rformat.methods.r_CarType(this.shortTrainMonth.list.query.vehType)}`,`科目: ${this.$rformat.methods.r_projectItemy(this.shortTrainMonth.list.query.stage)}`];
                for(let i = 0;i < this.shortTrainMonth.columns.length - 5; i++){
                    this.selName.push(" ")
                    console.log(' this.selName', this.selName)
                }
                // let excelsource = this.comexport(this.shortTrainMonth.columns, this.shortTrainMonth.list.results, false, 'no');
                let excelsource= this.comexport(this.shortTrainMonth.columns,this.shortTrainMonth.list.results);
                excelsource=this.exportDate1(excelsource);
                this.excelData=[... this.excelData,...excelsource];
                this.excelData.push([`结算日期:${this.shortTrainMonth.list.query.begin}-${this.shortTrainMonth.list.query.end}`," ",`合计:${this.getTotal()}`]);
                // for (let i = 0; i < this.shortTrainMonth.columns.length - 6; i++) {
                //     this.excelData[this.excelData.length - 1].push(" ")
                // }

            })
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        drawLine() {
            // 初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            myChart.showLoading();
            window.onresize = myChart.resize;
            // 绘制图表
            myChart.setOption({
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        magicType: {
                            show: true,
                            type: ['pie', 'funnel']
                        },
                        saveAsImage: { show: true },
                        saveAsImage: { show: true },
                        magicType: { show: true, type: ['line', 'bar'] }
                    }
                },
                title: { text: '月报' },
                tooltip: {},
                xAxis: {
                    axisLabel: {
                        interval: 0,
                        rotate: 38
                    },
                    data: this.shortTrainMonth.list.dataa
                },
                yAxis: {},
                series: [{
                    name: '金额',
                    type: 'bar',
                    data: this.shortTrainMonth.list.dataaa,
                    itemStyle: {
                        normal: {
                            color: '#40a9ff',
                            label: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: 'red',
                                    fontSize: 16
                                }
                            }
                        }
                    }
                }]

            });
            myChart.hideLoading();
        },
        getDateStr(e){
            this.shortTrainMonth.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.shortTrainMonth.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.shortTrainMonth.daterange = e;
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
          },
    },
    computed: {
        hasSelected() {
            return this.shortTrainMonth.bindResult.radResult > 0
        }
    },
    created() {
    },
    mounted() {
        this.shortTrainMonth.columns = this.shortTrainMonth.bindResult.columns.filter(e => {
            return  e.dataIndex != "CouponPrice"
        });
        this.cTypeClumn = this.shortTrainMonth.bindResult.columns.filter(e => {
            return  e.dataIndex != "CouponPrice"
        })
        // if(!this.$route.meta.keepAlive){
        //     this.isFav();
        //     this.drawLine();
        //     this.queryTrainMonth();
        //   }
        this.isFav();
        this.drawLine();
        this.queryTrainMonth();
    },
    activated(){
         this.isFav();
         this.drawLine();
        //  this.queryTrainMonth();
      }
};
