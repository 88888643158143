import {
    faxInfo,
    getFaxInfo
} from "@/service/dev/index";
function hasErrors (fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
export default {
    data () {
        return {
            unitId: sessionStorage.getItem('unitId'),
            hasErrors,
            form: this.$form.createForm(this),
        };
    },
    mounted () {
        this.$nextTick(() => {
            // To disabled submit button at the beginning.
            this.form.validateFields();
        });
    },
    methods: {
        kpjhError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('kpjh') && getFieldError('kpjh');
        },
        companyError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('company') && getFieldError('company');
        },
        invoiceError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('invoice') && getFieldError('invoice');
        },
        addressError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('address') && getFieldError('address');
        },
        telError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('tel') && getFieldError('tel');
        },
        contactError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('contact') && getFieldError('contact');
        },
        handleSubmit  (e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    let querys={...values,unitId: sessionStorage.getItem('unitId')}
                    querys.faxRatio=values.faxRatio/100
                    faxInfo(querys).then(res=>{
                        if(!res.message){
                            this.$message.success('信息保存成功')
                        }
                        else {
                            this.$message.error(res.message)
                        }
                    })
                    // console.log('Received values of form: ', values);
                }
            });
        },
        selectInfo(){
            let querys={unitId: sessionStorage.getItem('unitId')}
            getFaxInfo(querys).then(res=>{
                this.form.setFieldsValue({
                    kpjh:res.data.deviceNo,
                    company:res.data.company,
                    invoice:res.data.invoice,
                    faxRatio:res.data.faxRatio*100,
                    address:res.data.address,
                    tel:res.data.tel,
                    contact:res.data.contact
                })
            })
        }
    },
    mounted() {
       this.selectInfo();
    }
};