import {
    getTeaList,
    getCount,
    addTea,
    modifyTea,
    delTea,
    getvehList,
    getvehCount,
    addManCarBind,
    unBind
} from "@/service/resManagement/index";
export default {
    //新增订单
    name: "saferManagement",
    data() {
        return {
            //编辑页面数据
            saferManagement: {
                list: {
                    query: {
                        name: '',
                        phone: '',
                        starId: -1,
                        pageIndex: 1,
                        pageSize: 10,
                        unitId: sessionStorage.getItem('unitId'),
                        teaId: '',
                        vehType: '',
                        userId: sessionStorage.getItem('userId'),
                        certId: '',
                    },
                    total: 0,
                    results: []
                },
                manCarList: {
                    select: {
                        name: '',
                        phone: '',
                        vehType: '',
                        stage: '',
                        vehNo: '',
                        veh: '',
                        status: '',
                        unitId: sessionStorage.getItem('unitId'),
                        pageIndex: 1,
                        pageSize: 100,
                        vehId: '',
                        starId: -1,
                        userId: sessionStorage.getItem('userId'),
                    },
                    total: 0,
                    resultss: [],
                    bindTeaId: 0
                },
                modifyTrain: false,
                modalTitle: '',
                optionItem: '',
                manCarVisible: false,
            },
            starFee: {
                list: [],
                serchList: [],
                modifyList: []
            },
            userId: sessionStorage.getItem('userId'),
            basicForm: this.$form.createForm(this),
            //表头
            columns: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "姓名",
                    dataIndex: "name",
                },
                {
                    title: "身份证号",
                    dataIndex: "certId",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                },
                {
                    title: "车型",
                    dataIndex: "vehType",
                },
                {
                    title: "带多车",
                    dataIndex: "canAny",
                    customRender: (item) => {
                        return item ? '是' : '否';
                    }
                },
                {
                    title: "星级名称",
                    dataIndex: "starId",
                    customRender: (item) => {
                        return item ? this.$enums.starClass[item] : '暂无';
                    }
                },
                {
                    title: '已绑定车号',
                    dataIndex: 'vehNo',
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ],
            //人车绑定表头
            columnss: [
                //     {
                //     width: 75,
                //     title: "序号",
                //     align: "center",
                //     scopedSlots: {
                //         customRender: "SerialNumbers"
                //     }
                // },
                {
                    width:100,
                    title: '车牌号',
                    dataIndex: 'veh',
                }, {
                    width:100,
                    title: '车号',
                    dataIndex: 'vehNo',
                }, {
                    width:100,
                    title: '科目',
                    dataIndex: 'stage',
                }, {
                    width:100,
                    title: '车型',
                    dataIndex: 'vehType',
                }
            ],
            excelName: ['安全员管理'],
            selName: [],
            excelData: [],
            bindResult:{
                radResult: [],
                selectedInfo: [],
                 columns: [
                    {
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        }
                    }, {
                        width:100,
                        title: "车牌号",
                        dataIndex:"veh",
                    },
                    {
                        width:100,
                        title: "车号",
                        dataIndex:"vehNo",
                    },
                    {
                        width:100,
                        title: "科目",
                        dataIndex: "stage",
                        customRender: item =>{
                               return `科目${item}`
                        }
                    },
                    {
                        width:100,
                        title: "车型",
                        dataIndex:"vehType",
                    }, {
                        width:100,
                        title: "操作",
                        scopedSlots: {
                            customRender: "operation"
                        }
                    }
                ]
            }
        };
    },
    methods: {
        e(val) {
            console.log('value', val);
        },
        querySaferManag(queryBy) {
            let querys = this.saferManagement.list.query;
            // 判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getCount(querys).then((res) => {
                this.saferManagement.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    getTeaList(querys).then((res) => {
                        this.$nextTick(() => {
                            this.saferManagement.list.results = res.data;
                        })
                        // console.log(res);
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$nextTick(() => {
                        this.saferManagement.list.results = [];
                    })
                }
            }).catch((err) => {
                console.log('err', err)
            })
        },
        getDate(){
            this.resetExport();
            let querys = this.saferManagement.list.query;
            let serch ={...querys};
            serch.pageIndex = 1;
            serch.pageSize = this.saferManagement.list.total + 100;
            getTeaList(serch).then(res =>{
                this.selName = [`电话号码：${this.saferManagement.list.query.phone}`, `车型: ${this.$rformat.methods.r_driverCarType(this.saferManagement.list.query.vehType)}`, `星级: ${this.$rformat.methods.r_starClass(this.saferManagement.list.query.starId)}`, " ", " ", " ", " ", " "];
                let excelsource = this.comexport(this.columns, res.data);
                excelsource = this.exportDate(excelsource);
                this.excelData = [... this.excelData, ...excelsource];
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        modifyData(record) {
            this.saferManagement.modifyTrain = true;
            if (record) {
                this.saferManagement.modalTitle = '修改安全员数据';
                this.saferManagement.optionItem = 'modifyData';
                this.$nextTick(() => {
                    this.saferManagement.list.query.teaId = record.teaId;
                    this.basicForm.setFieldsValue({
                        name: record.name,
                        certId: record.certId,
                        phone: record.phone,
                        vehType: record.vehType,
                        canAny: record.canAny,
                        starId: record.starId,
                        status: record.status
                    });
                })
            } else {
                this.saferManagement.modalTitle = '增加安全员数据';
                this.saferManagement.optionItem = 'addData';
            }
        },
        bindCar(record) {
            this.saferManagement.manCarList.bindTeaId = record.teaId;
            this.manCarBind();
        },
        //人车绑定列表查询
        manCarBind(querysBy) {
            this.saferManagement.manCarVisible = true;
            let query = this.saferManagement.manCarList.select;
            //判断是否为点击页码查询
            if (querysBy !== "paginations") {
                query.pageIndex = 1;
            }
            getvehCount(query).then((res) => {
                this.saferManagement.manCarList.total = res.data.rowCount;
                if (res.data.rowCount) {
                    getvehList(query).then((res) => {
                        this.$nextTick(() => {
                            this.saferManagement.manCarList.resultss = res.data;
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.saferManagement.manCarList.results = [];
                    this.saferManagement.manCarList.total = 0;
                }
            }).catch((err) => { })
        },
        //单选事件
        radSelect(e) {
            console.log(`checked = ${e.target.checked}`)
            // console.log('heihei',ele)
            // this.saferManagement.radResult.push(ele);
            // console.log('ele',ele)
        },
        manCarSave() {
            let i = 1;
            this.bindResult.selectedInfo.map(item => {
                let { unitId, veh, vehId } = item;
                let params = { bindTeaId: this.saferManagement.manCarList.bindTeaId, unitId, veh, vehId }
                // console.log('params',params)
                addManCarBind(params).then((res) => {
                    if (i === this.bindResult.selectedInfo.length) {
                        if (!res.message) {
                            this.$message.success('绑定成功');
                            this.saferManagement.manCarVisible = false;
                            this.bindResult.radResult = [];
                            this.bindResult.selectedInfo = [];
                            this.querySaferManag();
                        } else {
                            this.$message.error(res.message)
                        }
                    }
                    i++;
                }).catch((error) => {
                    console.log(error);
                });
            })
        },
        //人车解绑
        unBindCar(record) {
            let params = { unitId: sessionStorage.getItem('unitId'), veh: record.veh, vehId: record.vehId }
            console.log('params', params)
            unBind(params).then(res => {
                if (!res.message) {
                    this.$message.success("解绑成功");
                    this.querySaferManag();
                }
                else {
                    this.$message.error(res.message)
                }
            })
        },
        // 新增，修改确定事件
        confirmRevision() {
            this.basicForm.validateFields((err, values) => {
                if (!err) {
                    let querys = this.saferManagement.list.query;
                    values.teaId = querys.teaId;
                    values.unitId = querys.unitId;
                    values.canAny = values.canAny ? 1 : 0;
                    this.saveDate(values);
                }
            });
        },
        // 添加调用接口
        saveDate(values) {
            if (this.saferManagement.optionItem === 'addData') {
                values.status = 0;
                addTea(values).then((res) => {
                    if (res.message.length) {
                        this.$message.error(res.message)
                    } else {
                        this.resetInfo();
                        this.$message.success('新增成功');
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            } else {
                modifyTea(values).then((res) => {
                    if (res.message.length) {
                        this.$message.error(res.message)
                    } else {
                        this.resetInfo();
                        this.$message.success('修改成功');
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            }
        },
        delData(record) {
            delTea({
                'teaId': record.teaId
            }).then((res) => {
                if (res.message.length) {
                    this.$message.error(res.message)
                } else {
                    this.querySaferManag();
                    this.$message.success('删除成功');
                }
            }).catch((err) => {

            })
        },
        // 重置信息
        resetInfo() {
            this.querySaferManag();
            this.saferManagement.modifyTrain = false;
            this.handleFormFieldsReset(this.basicForm);
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            const veh = "veh";

            // key 序号
            //selectedInfo 选择的行
            this.bindResult.radResult = [...selectedRowKeys];
            selectedRows.map(item => {
                this.bindResult.selectedInfo.push(item);
            });
            const r = this.bindResult.selectedInfo.reduce((all, next) => all.some((atom) => atom[veh] == next[veh]) ? all : [...all, next], []);
            this.bindResult.selectedInfo = r.filter(item => {
                return this.bindResult.radResult.some((e) => {
                    return item.veh === e;
                });
            });
        },
        delSelCheck(record){
            // console.log('heihei',record)
            this.bindResult.radResult= this.bindResult.radResult.filter( item=>{
                return item!==record.veh;
            })
            this.bindResult.selectedInfo=this.bindResult.selectedInfo.filter(item=>{
                return item.veh!==record.veh;
            })

        }
    },
    computed: {
        hasSelected() {
            return this.bindResult.radResult > 0
        }
    },
    watch: {},
    created() { },
    mounted() {
        this.querySaferManag();
        this.starFee.list =JSON.parse(sessionStorage.getItem('starCost'));
        // console.log('heihei',sessionStorage.getItem('starCost'));
        let arr = this.starFee.list.filter(item => { return item.starType === 2 });
        let arrObj = [{ 'starName': '全部', 'starId': -1 }];
        let arrObj2 = [{ 'starName': '无', 'starId': 0 }]
        this.starFee.serchList = [...arr, ...arrObj];
        this.starFee.modifyList = [...arr, ...arrObj2];
        this.isFav();
    },
    activated() {
        this.starFee.list =JSON.parse(sessionStorage.getItem('starCost'));
        let arr = this.starFee.list.filter(item => { return item.starType === 2 });
        let arrObj = [{ 'starName': '全部', 'starId': -1 }];
        let arrObj2 = [{ 'starName': '无', 'starId': 0 }]
        this.starFee.serchList = [...arr, ...arrObj];
        this.starFee.modifyList = [...arr, ...arrObj2];
        this.isFav();
    },
};