import {
    getLocalTotal,
    getLocalDetail,
    getTrainPhotoByStuCertId,

} from "@/service/charge/index";
export default {
    name: "schoolTraining",
    data() {
        return {
            vueAmaps: {
                zoom: 13,
                center: [104.762406, 31.420331],
                vdata: [],//车辆定位数据
                vmarkers: [],//车辆定位
                infoWindow: {},//信息窗体
                cluster: null,//点聚合
                texts: [],
                currentWindow: [],
                window: {}
            },
            expandedRowKeysNew:[],
            schoolTraining: {
                list: {
                    query: {
                        date: this.$moment().format('YYYYMMDD'),
                        teaCertId: '',
                        eId: sessionStorage.getItem('eId'),
                        pageIndex: 1,
                        pageSize: 10
                    },
                    total: 0,
                    tatolData: [],
                    detailData: []
                },

            },
            img: {
                previewVisible: false,
                previewImage: "",
            },
            searchText: '',
            searchInput: null,
            stageMarkDeatil: {
                visible: false,
                data: {},
                tabindex: "1",
                trainPhotos: [],
                checkPhotos: [],
                previewImage: '',
                previewVisible: false,
            },
            //汇总表头
            columns: [
                { title: '教练', dataIndex: 'name', scopedSlots: { customRender: 'pic' } },
                // { title: '教练姓名', dataIndex: 'name' },
                { title: '身份证号', dataIndex: 'teaCertId' },
                { title: '学员人数', dataIndex: 'personCount' },
                {
                    title: '总小时数', dataIndex: 'minutesTotal',
                    customRender: (item) => {
                        return `${parseInt(item)}分钟 ${parseInt(item / 60) ? `(${parseInt(item / 60)}小时)` : ``}`
                    }
                },
            ],
            //详情汇总表头
            stuColumns: [
                { title: '学员头像', dataIndex: 'photo', scopedSlots: { customRender: 'picc' } },
                // { title: '学员姓名', dataIndex: 'name' },
                {
                    title: '身份证号', dataIndex: 'stuCertId',
                    key: 'stuCertId',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value, record) => record.stuCertId.toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: (visible) => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus()
                            }, 0)
                        }
                    },
                },
                { title: '科目', dataIndex: 'stage' },
                { title: '车号', dataIndex: 'vehNo', sorter: (a, b) => a.vehNo - b.vehNo },
                {
                    title: '培训开始时间', dataIndex: 'begin',
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        } else {
                            if (typeof item === 'number') {
                                return this.translateDate(item);
                            }
                        }
                    }
                },
                {
                    title: '培训结束时间', dataIndex: 'end',
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        } else {
                            if (typeof item === 'number') {
                                return this.translateDate(item);
                            }
                        }
                    }
                },
                {
                    title: '培训时长(分钟)',
                    dataIndex: 'trainTime',
                    customRender: item => {
                        return item ? item : "0";
                    }
                },
                {
                    title: '轨迹',
                    dataIndex: 'classId',
                    scopedSlots: { customRender: 'classId' }
                },
            ],
            excelName: ['本校教练培训详情'],
            selName: [],
            excelData: [],
        }
    },
    methods: {
        dataSelect(date, dateString) {
            this.schoolTraining.list.query.date = date.format("YYYYMMDD");
        },
        onSearch(e) {
            this.schoolTraining.list.query.teaCertId = e.target.value ? e.target.value : "";
        },
        queryData(queryBy) {
            this.expandedRowKeysNew = []
            let param = this.schoolTraining.list.query
            if (queryBy !== "pagination") {
                param.pageIndex = 1;
            }
            getLocalTotal(param).then(res => {
                this.schoolTraining.list.tatolData = res.data;
                this.schoolTraining.list.total = res.data.length;
                this.schoolTraining.list.tatolData = this.schoolTraining.list.tatolData.map(item => {
                    item.detailData = [];
                    return item;
                });
            })
        },
        getDate(){
            this.resetExport();
            let param = this.schoolTraining.list.query;
            let serch ={...param};
            serch.pageIndex = 1;
            serch.pageSize = this.schoolTraining.list.total + 100;
            getLocalTotal(serch).then(res => {
                this.selName = [`身份证号: ${this.schoolTraining.list.query.teaCertId}`, `选择时间: ${this.schoolTraining.list.query.date}`];
                for (let i = 0; i < this.columns.length - 2; i++) {
                    this.selName.push(" ")
                }
                let excelsource = this.comexport(this.columns,res.data, true);
                excelsource = this.exportDate(excelsource);
                this.excelData = [... this.excelData, ...excelsource];
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        expandBtn(e, record) {
            if (!e) {
                return;
            }
            let param = this.schoolTraining.list.query
            param.teaCertId = record.teaCertId
            let isExist = this.schoolTraining.list.tatolData.filter(item => {
                if (item.teaCertId === record.teaCertId) {
                    if (item.detailData.length) {
                        return item;
                    }
                }
            });
            if (isExist.length) {
                return;
            }
            getLocalDetail(param).then(res => {
                this.schoolTraining.list.tatolData = this.schoolTraining.list.tatolData.map(item => {
                    if (item.teaCertId === record.teaCertId) {
                        item.detailData = res.data.map((item, index) => {
                            item.id = index;
                            let begin = this.$moment(this.formatUnix(item.begin));
                            let end = this.$moment(this.formatUnix(item.end));
                            item.trainTime = end.diff(begin, 'minute');
                            return item;
                        });
                    }
                    this.schoolTraining.list.query.teaCertId = ''
                    return item;
                })
            })
        },
        handleExpandedRowsChange(expandedRows) {
            this.expandedRowKeysNew=expandedRows
            this.schoolTraining.list.query.teaCertId = ''
        },
        viewImg(url) {
            this.img.previewVisible = true
            this.img.previewImage = url;
        },
        handleSearch(selectedKeys, confirm) {
            confirm()
            this.searchText = selectedKeys[0];
        },
        handleReset(clearFilters) {
            clearFilters()
            this.searchText = ''
        },
        formatUnix(item) {
            if (typeof item === 'number') {
                return this.translateDate(item);
            }
        },
        viewTrack(record) {
            record.trainBegin = record.begin;
            record.trainEnd = record.end;
            this.stageMarkDeatil.data = record;
            let query = {
                begin: this.$moment(this.translateDate(record.begin)).format('YYYY-MM-DD HH:mm:ss'),
                end: this.$moment(this.translateDate(record.end)).format('YYYY-MM-DD HH:mm:ss'),
                certId: record.stuCertId
            }
            getTrainPhotoByStuCertId(query).then(res => {
                // console.log('res',res.data);
                this.stageMarkDeatil.trainPhotos = res.data;
                this.stageMarkDeatil.checkPhotos = res.data;
                this.stageMarkDeatil.visible = true;
            })
        },
        formatDateTime(val) {
            let date = val.split('T');
            let dateMonth = date[0];
            let min = date[1].substr(0, date[1].indexOf('.'));
            return dateMonth + ' ' + min;
        },
        tabChange(val) {
            this.stageMarkDeatil.tabindex = val;
        },
        closeModel() {
            this.stageMarkDeatil.visible = false;
            this.stageMarkDeatil.tabindex = '1';
            this.stageMarkDeatil.isSearch = false;
            this.vueAmaps.markers = [];
            this.stageMarkDeatil.trainPhotos = []
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
    },
    mounted() {
        this.queryData();
    }
}