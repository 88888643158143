import { getGpsDataByCar } from "@/service/businessData/index";
import { getvehList } from "@/service/resManagement/index";
import {message} from "ant-design-vue";
export default {
    name: "CarRuntimeReport",
    data() {
        return {
            CarRuntimeReport: {
                list: {
                    query: {
                        eId: sessionStorage.getItem('eId'),
                        begin: this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        vehId: '',
                        flag:'12',
                    },
                    vehNo:'',
                    stage:'2',
                    sunTotal: [],
                    total: 0,
                    results: []
                },
                daterange: [this.$moment(), this.$moment()],
                screenHeight: 500,
                // columns: []
            },
            //表头
            columns: [
                {
                    width: 60,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "开始时间",
                    dataIndex: "begin",
                },
                {
                    title: "结束时间",
                    dataIndex: "end",
                },
            ],
        }
    },
    methods: {
        // getDateStr(e) {
        //     this.CarRuntimeReport.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
        //     this.CarRuntimeReport.list.query.end = this.$moment(e[1]).add(1, 'days').format('YYYYMMDD');
        //     this.CarRuntimeReport.daterange = e;
        // },
        dataSel(date, dateString){
            console.log('data',date)
            this.CarRuntimeReport.list.query.begin=date.format("YYYYMMDD");
            console.log('this.CarRuntimeReport.list.query.begin',this.CarRuntimeReport.list.query.begin)
            var endTime =date
            var end = JSON.parse(JSON.stringify(endTime))
            console.log('end',end)
            var datee = new Date(end);
            var date1 = this.time(datee);
            var haha = this.formatterDate(date1)
            this.CarRuntimeReport.list.query.end = this.$moment(haha).add(1, 'days').format('YYYYMMDD');
            console.log('this.CarRuntimeReport.list.query.end',this.CarRuntimeReport.list.query.end)
        },
        formatterDate (date) {
            let result = new Date(date);
            return result;
        },
        time(date) {
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            var d = date.getDate()
            d = d < 10 ? '0' + d : d
            var h = date.getHours()
            h = h < 10 ? '0' + h : h
            var minute = date.getMinutes()
            minute = minute < 10 ? '0' + minute : minute
            var second = date.getSeconds()
            second = second < 10 ? '0' + second : second
            return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
        },
        selectData(){
            let params = {userId:sessionStorage.getItem('userId'),unitId:sessionStorage.getItem('unitId'),pageIndex:1,pageSize:10,veh:'',vehType:'',vehNo:this.CarRuntimeReport.list.vehNo,stage:this.CarRuntimeReport.list.stage,starId:-1,status:''}
            getvehList(params).then(res =>{
                if(res.data){
                    this.CarRuntimeReport.list.query.vehId = res.data[0].vehId
                    let param = this.CarRuntimeReport.list.query
                    getGpsDataByCar(param).then(res =>{
                        if(res.data){
                            this.CarRuntimeReport.list.results =res.data.map(item =>{
                                item.begin = this.timestampToTime(item.begin)
                                item.end = this.timestampToTime(item.end)
                                return item
                            })
                        }else {
                            this.CarRuntimeReport.list.results=''
                        }
                    })
                } else {
                    message.error('请输入正确的车号')
                }
               // this.CarRuntimeReport.list.query.vehId = res.data[0].vehId
               //  console.log('vehId',this.CarRuntimeReport.list.query.vehId)
            })
            // let param = this.CarRuntimeReport.list.query
            // getGpsDataByCar(param).then(res =>{
            //     console.log('259', res)
            // })
            //   console.log('258',this.CarRuntimeReport.list.query)
        },
        //将时间戳转换为普通时间
        timestampToTime(timestamp) {
            let unixtime = timestamp;
            let unixTimestamp = new Date(unixtime*1000);
            let Y = unixTimestamp.getFullYear(),
                M = ((unixTimestamp.getMonth() + 1) > 10 ? (unixTimestamp.getMonth() + 1) : '0' + (unixTimestamp.getMonth() + 1)),
                D = (unixTimestamp.getDate() >= 10 ? unixTimestamp.getDate() : '0' + unixTimestamp.getDate()),
                h = (unixTimestamp.getHours()<10) ? "0"+unixTimestamp.getHours() : unixTimestamp.getHours(),
                min = (unixTimestamp.getMinutes()<10) ? "0"+unixTimestamp.getMinutes() : unixTimestamp.getMinutes(),
                s = (unixTimestamp.getSeconds()<10) ? "0"+unixTimestamp.getSeconds() : unixTimestamp.getSeconds();
            let toDay = Y + '-' + M + '-' + D+ " " +h + ":" +min + ":" +s;
            return toDay;
        }
    },
    created() {

    },
    mounted() {
        this.isFav();
        this.screenHeight = document.documentElement.scrollHeight - 300;
    },
    activated() {
        this.isFav();
        this.screenHeight = document.documentElement.scrollHeight - 300;
        //  this.queryShortData();
    }
};
