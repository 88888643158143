import { getCouponCount, getCouponList, delCounpon, couponInsBatch } from "@/service/systemSetup";
export default {
  //新增订单
  name: "couponManage",
  data() {
    return {
      //编辑页面数据
      couponManage: {
        list: {
          query: {
            eId: sessionStorage.getItem('eId'),
            pageIndex: 1,
            pageSize: 10
          },
          total: 0,
          results: []
        },
      },
      //表头
      columns: [
        {
          width: 75,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        }, {
          width: 100,
          title: "优惠券编号",
          dataIndex: "coupon",
        }, {
          width: 100,
          title: "科目",
          dataIndex: "stage",
        }, {
          width: 100,
          title: "录入用户",
          dataIndex: "nickName",
        }, {
          width: 100,
          title: "使用状态",
          dataIndex: "useStatus",
          customRender: (item) => {
            return item == 0 ? '未使用' : ''
          }
        }, {
          width: 100,
          title: "录入时间",
          dataIndex: "inTime",
          customRender: (item) => {
            return item ? this.transForm(item) : ''
          }
        },
        {
          width: 75,
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['优惠券管理'],
      addcoupon: {
        couponTitle: '新增优惠券',
        couponViable: false
      },
      selName: [],
      excelData: [],
      basicForm: this.$form.createForm(this),
      userId: sessionStorage.getItem('userId'),
      outputs: []
    };
  },
  methods: {
    // 查询列表
    queryCoupon(queryBy) {
      let querys = this.couponManage.list.query;
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getCouponCount(querys).then(res => {
        if (res.data.rowCount) {
          this.couponManage.list.total = res.data.rowCount;
          getCouponList(querys).then(res => {
            this.couponManage.list.results = [...res.data];
          })
        }
      })

    },
    // 删除
    delCounpon(record) {
      let query = { eId: sessionStorage.getItem('eId'), coupon: record.coupon, stage: record.stage }
      delCounpon(query).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.queryCoupon();
          this.$message.success('此优惠卷已作废!');
        }
      });
    },
    // 新增
    couponInsBatch() {
      let querys = { eId: sessionStorage.getItem('eId'), userId: sessionStorage.getItem('userId') };
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          let couponStr = values.coupons.replace(/[(\r\n)|(\ +)|(，)|(,,)]/g,',');
          let couponArr = couponStr.split(',').filter(item =>{return item});
          querys.coupons =[];
          if(couponArr.length>1){
            couponArr.map(item =>{
              querys.coupons.push(`${values.stage}|${item}`);
            })
          }else{
            querys.coupons = [`${values.stage}|${values.coupons}`];
          }
          couponInsBatch(querys).then((res) => {
            if (res.message.length) {
              this.$message.error(res.message)
            } else {
              this.resetInfo();
              this.$message.success('优惠券新增成功!');
            }
          });
        }
      });
    },
    getDate(){
      let querys = this.couponManage.list.query;
      this.resetExport();
      let serch = { ...querys };
      serch.pageIndex = 1;
      serch.pageSize = this.couponManage.list.total + 100;
      getCouponList(serch).then(res => {
        for (let i = 0; i < this.columns.length - 1; i++) {
          this.selName.push(" ")
        }
        let excelsource = this.comexport(this.columns,res.data);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
      });
    },
    // 重置model
    resetInfo() {
      this.queryCoupon();
      this.handleFormFieldsReset(this.basicForm);
      this.addcoupon.couponViable = false;
    },
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
    mulcoupon(){
     this.addcoupon.couponViable=true;
     this.$nextTick(()=>{
      this.excelListener();
     })
    },
    async excelListener() {
       await this.$refs.upload.addEventListener('change', e => {//绑定监听表格导入事件
       this.readExcel(e);
      })
    },
    readExcel(e) {//表格导入
      var that = this;
      const files = e.target.files;
      if (files.length <= 0) {//如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式');
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = this.$XLSX.read(data, {
            type: 'binary'
          });
          const wsname = workbook.SheetNames[0];//取第一张表
          const ws = this.$XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);//生成json表格内容
          that.outputs = [];//清空接收数据
          for (var i = 0; i < ws.length; i++) {
            // var sheetData = {
            //   coupon: ws[i]['优惠券号'],
            // }
            let sheetData=ws[i]['优惠券号']
            that.outputs.push(sheetData);
          }
          this.basicForm.setFieldsValue({'coupons':that.outputs.join(',')})
          this.$refs.upload.value = '';

        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
  },
  watch: {
  },
  created() { },
  mounted() {
    this.queryCoupon();
    this.isFav();
  },
  activated() {
    this.isFav();
  }
};