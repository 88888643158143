const parameterSettings = resolve =>require(["@/views/systemSetup/appointment/parameterSettings/index.vue"], resolve);
const dateLocking = resolve =>require(["@/views/systemSetup/appointment/dateLocking/index.vue"], resolve);
const reservationVehicles = resolve =>require(["@/views/systemSetup/appointment/reservationVehicles/index.vue"], resolve);
export default {
    //新增订单
    name: "appointment",
    data() {
        return {}
    },
    methods: {
    },
    watch: {},
    created() {
    },
    components: {
        parameterSettings,
        dateLocking,
        reservationVehicles
    },
    mounted() {}
};