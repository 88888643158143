import {
    devAdd
} from "@/service/dev/index";
export default {
    data() {
        return {
            form:{
                add:{
                     unitId:1,
                     factory:'成都网阔信息技术股份有限公司',
                }
            },
            currentt: -1,
            basicForm: this.$form.createForm(this),
            formLayout: 'Vertical',
        };
    },
    computed: {
        formItemLayout() {
            const {formLayout} = this;
            return formLayout === 'horizontal' ? {
                labelCol: {span: 4},
                wrapperCol: {span: 14},
            } : {};
        },
        buttonItemLayout() {
            const {formLayout} = this;
            return formLayout === 'horizontal' ? {
                wrapperCol: {span: 14, offset: 4},
            } : {};
        },
    },
    methods: {
        handleFormLayoutChange(e) {
            this.formLayout = e.target.value;
        },
        handleSubmit() {
            let querys = this.form.add;
            this.basicForm.validateFields((err, values) => {
                if (!err) {
                    values.unitId = querys.unitId;
                    values.factory = querys.factory;
                    // console.log('values',values)
                    return;
                    devAdd(values).then((res) => {
                        if (res.message.length) {
                            this.$message.error(res.message)
                        } else {
                            this.$message.success('新增成功');
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            });
        },
    },
}