import {
  getVipCount,
  getVipList,
  validVip
} from "@/service/resManagement/index";
import {
  openDownLoad
} from "@/utils/index";
export default {
  //新增订单
  name: "vipQua",
  data() {
    return {
      //编辑页面数据
      vipQua: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            name: '',
            phone: '',
            oncePhone: '',
            status: '',
            unitId:sessionStorage.getItem('unitId'),
            // 可为0,可为1，默认为1
            vipType:1,
            userId: sessionStorage.getItem('userId'),
            valid:0
          },
          total: 0,
          results: []
        },
        modifyTrain: false,
        modalTitle: '',
        optionItem: '',
        driverList:[],
        selectedInfo: [],
        selectedKeys: [],
        img:{
          visible:false,
          url:'',
        }
      },
      userId:sessionStorage.getItem('userId'),
      //表头
      columns: [{
          title: "姓名",
          dataIndex: "name"
        },
        {
          title: "身份证号",
          dataIndex: "certId"
        },
        {
          title: "手机号",
          dataIndex: "phone"
        },
        {
          title: "驾校",
          dataIndex: "shortId",
          customRender:(item)=>{
            return this.vipQua.driverList.map(index=>{
              if (index.shortId === item){
                return index.shortName
              }
            })
          }
        },
        {
          title: "上传图片",
          dataIndex: "validPic",
          scopedSlots: {
            customRender: "validPic"
          }
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['vip资格认证'],
      selName:[],
      excelData:[],
    };
  },
  methods: {
    // 查询列表
    queryvipManage(queryBy) {
      let querys = this.vipQua.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getVipCount(querys).then((res) => {
        this.vipQua.list.total = res.data.rowCount;
        if (res.data.rowCount) {
          // console.log('hcaxun',querys);
          getVipList(querys).then((res) => {
            this.vipQua.list.results = res.data;
          });
        } else {
          this.vipQua.list.results = [];
          this.vipQua.list.total = 0;
        }
      });
    },
    getDate(){
      this.resetExport();
      let querys = this.vipQua.list.query;
      let serch ={...querys};
      serch.pageIndex = 1;
      serch.pageSize = this.vipQua.list.total + 100;
      getVipList(serch).then((res) => {
        this.vipQua.list.results = res.data;
        this.selName = [`VIP类型：${this.$rformat.methods.r_vipType(this.vipQua.list.query.vipType)}`, `车号: ${this.vipQua.list.query.phone}-${this.vipQua.list.query.name}`, " ", " ", " ", " "];
        let excelsource = this.comexport(this.columns, this.vipQua.list.results);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
      });

    },
    // 查看图片
    download(link) {
      openDownLoad(link);
    },
    // 姓名手机号查询
    onSearch(params) {
      let querys = this.vipQua.list.query;
      querys.phone = '';
      querys.name = '';
      if (this.checkNumber(params.target.value)) {
        querys.phone = params.target.value;
      } else {
        querys.name = params.target.value;
      }
      // this.queryvipManage();
    },
    //验证字符串是否是数字
    checkNumber(theObj) {
      theObj=theObj.trim();
      var reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(theObj)) {
        return true;
      }
      return false;
    },
    // 审核函数isPass true 通过 false否决 isAll代表是一键通过还是一键否决 index数组长度
    isValid(record, isPass, isAll, index) {
      const { phone,unitId, valid, validUserId } = record;
      let params = {  phone,  unitId,valid,validUserId }
      isPass ? params.valid = 1 : params.valid = 2;
      validVip(params).then(res => {
        if (res.message.length) {
          this.$message.error(res.message);
        } else {
          let querys = this.vipQua.list.query;
          this.$store.dispatch("StoreDataQuery/setQueryByNews",querys)
          if (isPass && !isAll) {
            this.queryvipManage();
            this.$message.success('已通过');
          } else if (!isPass && !isAll) {
            this.queryvipManage();
            this.$message.success('已否决');
          } else if (isPass && isAll && index + 1 === this.vipQua.selectedKeys.length) {
            this.queryvipManage();
            this.$message.success('全部通过');
            this.vipQua.selectedInfo=[];
          } else if (! isPass && isAll && index + 1 === this.vipQua.selectedKeys.length) {
            this.queryvipManage();
            this.$message.success('全部否决');
            this.vipQua.selectedInfo=[];
          }
        }
      });
    },
    // 判断是否选中
    validVip(record, isPass) {
      // if (this.vipQua.selectedKeys.length) {
      //   if (this.vipQua.selectedKeys.includes(record.phone)) {
      //          this.isValid(record, isPass, false);
      //   } else {
      //     this.$message.info("请先选择需审核项");
      //   }
      // } else {
      //   this.$message.info("还未选中审核项");
      // }
        this.isValid(record, isPass, false);
    },
    // 全部审核
    isAllPass(isPass) {
      // let a=new Map(this.vipQua.selectedInfo.map((item, index) => [index, item]))
      if (this.vipQua.selectedInfo.length) {
        for (let [index, item] of new Map(this.vipQua.selectedInfo.map((item, index) => [index, item]))) {
          this.isValid(item, isPass, true, index)
        }
      } else {
        this.$message.info("请先选择需要通过的数据");
      }
    },
    // 查看图片
    viewImg(url){
      this.vipQua.img.url=url;
      this.vipQua.img.visible=true;
    },
    resetExport(){
      this.selName=[];
      this.excelData=[];
    },
  },
  computed: {
    rowSelection() {
      const {
        selectedRowKeys
      } = this;
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          const phone = "phone";
          this.vipQua.selectedKeys = [...selectedRowKeys];
          selectedRows.map(item => {
            this.vipQua.selectedInfo.push(item);
          });
          const r= this.vipQua.selectedInfo.reduce((all, next) => all.some((atom) => atom[phone] == next[phone]) ? all : [...all, next], []);
          this.vipQua.selectedInfo= r.filter(item=>{
           return  this.vipQua.selectedKeys.some((e)=>{
              return item.phone === e;
            });
          }); 
        }
      };
    }
  },
  watch: {},
  created() {
    this.vipQua.driverList =JSON.parse(sessionStorage.getItem('driverSchool'));
    this.queryvipManage();
    this.isFav();
  },
  activated() {
    this.vipQua.driverList =JSON.parse(sessionStorage.getItem('driverSchool'));
     this.isFav();
  }
}; 