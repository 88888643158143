import {
  getCount,
  getList,
  createUser,
  DelUser,
  getInfo,
  getUserMenu,
  addUserMenu,
  cancelUserMenu
} from "@/service/userManagement/index";
export default {
  //新增订单
  name: "userManagement",
  data() {
    return {
      //编辑页面数据
      userManagement: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            nickName: '',
            phone: '',
            role: '',
            unitId: sessionStorage.getItem('unitId'),
            param: '',
            certId: '',
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: []
        },
        //权限model
        roleTrain: false,
        roleTitle: '权限设置',
        // 修改model
        modifyTrain: false,
        modalTitle: '修改安全员数据',
        // 重置密码
        pwdTrain: false,
        pwdTitle: '重置密码',
        optionItem: '',
        roleTree: {
          expandedKeys: [],
          oldselectkey: [],
          defalutKey:[],
          autoExpandParent: true,
          checkedKeys: [],
          selectedKeys: [],
          addkey: [],
          delkey: [],
          // 所有父级菜单
          allfakey: [],
          // 所有子集菜单
          allchkey: [],
          // 树形菜单操作数组
          optionCArr: [],
          treeData: [],
          userId: ''
        }
      },
      userId: sessionStorage.getItem('userId'),
      basicForm: this.$form.createForm(this),
      basicForm3: this.$form.createForm(this),
      //表头
      columns: [{
        width: 75,
        title: "序号",
        align: "center",
        scopedSlots: {
          customRender: "SerialNumber"
        }
      }, {
        title: "账号",
        dataIndex: "loginName",
      },
      {
        title: "角色类型",
        dataIndex: "",
        customRender: () => {
          return '用户';
        }
      },
      {
        title: "用户姓名",
        dataIndex: "nickName",
      },
      {
        title: "电话号码",
        dataIndex: "userPhone",
      },
      {
        title: "身份证号",
        dataIndex: "userCertId",
        customRender: (item) => {
          return item ? item : '暂无身份证号'
        }
      },
      {
        title: "操作",
        scopedSlots: {
          customRender: "operation"
        }
      }
      ],
      chfa:[],
      excelName: ['用户管理'],
      selName: [],
      excelData: [],
    };
  },
  methods: {
    // 查询列表
    querySaferManag(queryBy) {
      let querys = this.userManagement.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      let isPhone = this.checkNumber(this.userManagement.list.query.param);
      querys.phone = '';
      querys.nickName = '';
      if (isPhone) {
        querys.phone = this.userManagement.list.query.param

      } else {
        querys.nickName = this.userManagement.list.query.param
      }
      getCount(querys).then(res => {
        this.userManagement.list.total = res.data[0].rowCount;
        this.resetExport();
        if (res.data[0].rowCount) {
          getList(querys).then(res => {
            this.userManagement.list.results = res.data;
            let serch = { ...querys }
            serch.pageIndex = 1;
            serch.pageSize = this.userManagement.list.total + 100;
            getList(serch).then(res => {
              this.selName = [`姓名/身份证号：${this.userManagement.list.query.param}`, `角色类型: ${this.$rformat.methods.r_role(this.userManagement.list.query.role)}`, " ", " ", " ", " "];
              let excelsource = this.comexport(this.columns, res.data);
              excelsource = this.exportDate(excelsource);
              this.excelData = [... this.excelData, ...excelsource];
            })
            this.$store.dispatch("StoreDataQuery/setQueryByStarCost", querys);
            this.$store.dispatch("StoreDataQuery/setQueryBySchool", querys)
            this.getInfo();
          });
        }
      });
    },
    getDate(){
      this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
},
    // 注销用户
    DelUser(record) {
      let querys = this.userManagement.list.query;
      let param = {
        'userId': record.userId,
        'unitId': querys.unitId
      }
      DelUser(param).then(res => {
        if (!res.message) {
          this.$message.success('注销成功');
          this.querySaferManag();
        } else {
          this.$message.success('注销失败')
        }
      });
    },
    // 取消事件
    cancel() {
      this.handleFormFieldsReset(this.basicForm);
    },
    // 保存编辑数据
    saveDate(values) {
      if (this.userManagement.optionItem === 'addData') {
        let querys = this.userManagement.list.query;
        querys.name = values.name;
        querys.phone = values.phone;
        querys.userName = values.userName;
        if (values.certId) {
          querys.certId = values.certId;
        }
        createUser(querys).then(res => {
          if (res.data.rowCount) {
            this.$message.success('用户新增成功！');
            querys.name = '';
            querys.phone = '';
            querys.userName = '';
            this.querySaferManag();
          }
        });
      } else {
        console.log('这是修改');
      }

      this.handleFormFieldsReset(this.basicForm);
      this.userManagement.modifyTrain = false;
    },
    // 编辑数据
    modifyData(record) {
      this.userManagement.modifyTrain = true;
      if (record) {
        this.userManagement.modalTitle = '修改用户数据';
        this.userManagement.optionItem = 'modifyData';
        this.getBasicData(record);
      } else {
        // console.log('come in')
        this.userManagement.modalTitle = '增加用户数据';
        this.userManagement.optionItem = 'addData';
      }
    },
    // 编辑权限
    modifyrole(record) {
      this.userManagement.roleTree.userId = record.userId;
      this.getInfo(record.userId);
      this.userManagement.roleTrain = true;
      this.getBasicData(record);
    },
    modifypwd(record) {
      this.userManagement.pwdTrain = true;
      this.getBasicData(record);
    },
    // 基本信息数据获取
    getBasicData(record) {
      this.$nextTick(() => {
        this.basicForm3.setFieldsValue({
          role: '用户',
          userCertId: record.userCertId ? record.userCertId : '暂无',
          loginName: record.loginName,
          userPhone: record.userPhone,
        });
      })
    },
    // 添加信息
    confirmRevision() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          this.saveDate(values);
          this.handleFormFieldsReset(this.basicForm);
        }
      });
    },
    // 权限确定事件
    confirmRole() {
      this.userManagement.roleTree.addkey = [];
      this.userManagement.roleTree.delkey = [];
      this.userManagement.roleTree.optionCArr.map(item => {
        if (!item.isSel && item.userSel) {
          if(item.menuLevel == 2){
            this.userManagement.roleTree.addkey.push(item.parentId);
          }
          this.userManagement.roleTree.addkey.push(item.menuId);
        } else if (item.isSel && !item.userSel) {
          this.userManagement.roleTree.delkey.push(item.menuId)
        }
      });
      if (this.userManagement.roleTree.delkey.length) {
        const param = {
          userId: this.userManagement.roleTree.userId,
          menuIdList: this.userManagement.roleTree.delkey
        };
        cancelUserMenu(param).then(res => {
          if (!res.message) {
            this.$message.info('权限删除成功');
            this.userManagement.roleTree.delkey = [];
            if (this.userManagement.roleTree.addkey.length) {
              this.addUserMenu();
            }
            this.getUserMenu({ userId: this.userManagement.roleTree.userId });
          } else {
            this.$message.info('操作失败原因' + res.message);
          }
        }).catch(err => {
          console.log('err', err)
        })
      } else {
        if (this.userManagement.roleTree.addkey.length) {
          this.userManagement.roleTree.addkey = [...this.userManagement.roleTree.addkey, ...this.userManagement.roleTree.allfakey];
          this.addUserMenu();
        }
      }
      this.userManagement.roleTrain = false;
    },
    // 增加权限
    addUserMenu() {
      const param = {
        userId: this.userManagement.roleTree.userId,
        menuIdList: this.userManagement.roleTree.addkey
      };
      // console.log('param',param)
      addUserMenu(param).then(res => {
        if (!res.message) {
          this.userManagement.roleTree.addkey = [];
          this.$message.info('权限增加成功');
        } else {
          this.$message.info('操作失败原因' + res.message);
        }
      }).catch(err => {
        // console.log('res', err)
      })
    },
    confirmPwd() {
      this.userManagement.pwdTrain = false;
    },
    // 权限树操作
    onExpand(expandedKeys) {
      this.userManagement.roleTree.expandedKeys = expandedKeys
      this.userManagement.roleTree.autoExpandParent = false
    },
    onCheck(checkedKeys, eTarget) {
      let optionKey = eTarget.node.eventKey;
      if (this.userManagement.roleTree.allfakey.includes(optionKey)) {
        for (let item of this.allItem(optionKey, 'reduce')) {
          let arr = this.userManagement.roleTree.optionCArr.map(e => {
            if (e.menuId === item) {
              e.userSel = eTarget.checked;
            }
            return e
          });
          this.userManagement.roleTree.optionCArr = arr;
        }
      } else {
        let arr = this.userManagement.roleTree.optionCArr.map(e => {
          if (e.menuId === optionKey) {
            e.userSel = eTarget.checked;
          }
          return e
        });
        this.userManagement.roleTree.optionCArr = arr;
      }
    },
    // 全选时，删选出要添加的项
    allItem(optionKey, params) {
      //所有菜单id
      let allArr = [];
      if(String(optionKey).substr(1, ) == '0000'){
         allArr = this.userManagement.roleTree.allchkey.filter(item => {
          return String(item).substr(0, 1) === String(optionKey).substr(0, 1) && item != optionKey
        });
      }else{
        allArr = this.userManagement.roleTree.allchkey.filter(item => {
          return String(item).substr(0, 3) === String(optionKey).substr(0, 3) && item != optionKey
        });
      }
      // console.log('come in',[...new Set(allArr)],optionKey);
      return [...new Set(allArr)];
    },
    onSelect(selectedKeys, info) {
      this.userManagement.roleTree.selectedKeys = selectedKeys
    },
    // 查询菜单数组
    getInfo(Id) {
      getInfo().then(res => {
        this.userManagement.roleTree.treeData = [];
        this.userManagement.roleTree.optionCArr = [];
        this.chfa = [];
        let userRoles = [];
        if (!res.message) {
          var obj = {};
          let  usermenudata = res.data.reduce(function(item, next) {
           obj[next.menuId] ? '' : obj[next.menuId] = true && item.push(next);
           return item;
          }, []);
          let map =usermenudata.reduce((res, v) => (res[v.menuId] = v, res), {})
          for (const item of usermenudata) {
            this.userManagement.roleTree.optionCArr.push({ isSel: false, menuId: item.menuId, userSel: false ,parentId:item.parentId, menuLevel:item.menuLevel})
            if (item.menuLevel == 0) {
              this.userManagement.roleTree.allfakey.push(item.menuId);
              item.title = item.menuText;
              item.key = item.menuId;
              item.menuName = item.menuName;
              userRoles.push(item);
              continue;
            }
            if (item.parentId in map) {
              const parent = map[item.parentId];
              parent.children = parent.children || [];
              if(item.menuName == 'vehicleMonitoring' || item.menuName == 'vehicleStatus'){
                this.userManagement.roleTree.allfakey.push(item.menuId);
              }
              if(item.menuLevel == 2){
               this.chfa.push(item.parentId)
              }
              item.title = item.menuText;
              item.key = item.menuId;
              item.menuName = item.menuName;
              this.userManagement.roleTree.allchkey.push(item.menuId);
              parent.children.push(item);
            }
          }
           this.userManagement.roleTree.treeData = [...userRoles];
        } else {
          this.$message.error(res.message)
        }
        if (Id) {
          this.getUserMenu({ userId: Id });
        }
      }).catch(err => {
        console.log('err', err);
      })
    },
    // 获取个人菜单
    getUserMenu(params) {
      getUserMenu(params).then(res => {
        let oldselectkey = []
        if (res.data) {
          for (let item of res.data) {
            if (item.menuLevel !== 0) {
              // 把具有按钮权限的除开
              if(!this.chfa.includes(item.menuId)){
                oldselectkey.push(item.menuId);
               }
            }
            let arr = this.userManagement.roleTree.optionCArr.map(e => {
              if (e.menuId === item.menuId) {
                e.isSel = true;
                e.userSel = e.isSel;
              }
              return e;
            })
            this.userManagement.roleTree.optionCArr = arr;
          }
        }
        this.userManagement.roleTree.oldselectkey = oldselectkey;
        this.userManagement.roleTree.checkedKeys = [...oldselectkey];
        // console.log(this.userManagement.roleTree.checkedKeys);
      }).catch(err => {
        console.log('err', err)
      })
    },
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
    //验证字符串是否是数字
    checkNumber(theObj) {
      theObj = theObj.trim();
      var reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(theObj)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    }
  },
  created() { },
  mounted() {
    this.querySaferManag();
    this.isFav();
  },
  activated() {
    this.isFav();
  }
};
