import {
    getVehListInnerDev,
    toggleTrainType,
    getLastLocation
} from "@/service/vehicleStatus/index";
import {
    getVipCount,
    getVipList,
    validVip
} from "@/service/resManagement/index";
import ARow from "ant-design-vue/es/grid/Row";
import { getUserMenu } from "../../../service/userManagement/index";

export default {
    name: "phoneVehicleStatus",

    components: { ARow },
    data() {
        return {
            phoneVehicleStatus: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 10,
                        //stage 默认0为全部
                        stage: 0,
                        //starId 默认-1为全部
                        starId: -1,
                        status: '',
                        unitId: sessionStorage.getItem('unitId'),
                        veh: '',
                        vehNo: '',
                        vehType: '',
                        //vip传参数
                        name: '',
                        phone: '',
                        oncePhone: '',
                        // 可为0,可为1，默认为1
                        vipType: 1,
                        userId: sessionStorage.getItem('userId'),
                        valid: 0
                    },
                    results: [],
                    firstLoad:false,
                    onlineResults: [],
                    vipResults: [],
                    totalResult:[],
                    tresults: [],
                    total: 0
                },
                driverList: [],
                selectedKeys: [],
                //vip审核表头
                columns: [{
                    title: "姓名",
                    dataIndex: "name"
                },
                {
                    title: "身份证号",
                    dataIndex: "certId"
                },
                {
                    title: "手机号",
                    dataIndex: "phone"
                },
                {
                    title: "驾校",
                    dataIndex: "shortId",
                    customRender: (item) => {
                        let arr = this.phoneVehicleStatus.driverList.filter(index => {
                            if (index.shortId === item) {
                                return index
                            }
                        })
                        if (arr.length) {
                            return arr[0].shortName
                        } else {
                            return "暂无";
                        }
                    }
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }],
                bindResult: {
                    radResult: [],
                    selectedInfo: [],
                    columns: [
                        {
                            title: "车号",
                            dataIndex: "vehNo",
                            defaultSortOrder: 'ascend',
                            scopedSlots: {
                                customRender: "vehNo"
                            },
                            sorter: (a, b) => a.vehNo - b.vehNo,
                            width:'19vw'
                        },
                        {
                            title: "车牌号",
                            dataIndex: "veh",
                            width:'23vw'
                        },
                        {
                            title: "车型",
                            width:'19vw',
                            dataIndex: "vehType",
                            filters: [{
                                text: '大车',
                                value: 'B',
                                
                            }, {
                                text: '小车',
                                value: 'C',
                            }],
                            filterMultiple: true,
                            onFilter: (value, record) => {
                                if (value == 'C') {
                                    return record.vehType.charAt(0) == 'C'
                                } else {
                                    return record.vehType.charAt(0) != 'C'
                                }
                            },
                        },
                        {
                            title: "科目",
                            width:'19vw',
                            dataIndex: "stage",
                            customRender: (item => {
                                return `科${item}`;
                            }),
                            filters: [{
                                text: '科2',
                                value: '2',
                            }, {
                                text: '科3',
                                value: '3',
                            }],
                            filterMultiple: true,
                            onFilter: (value, record) => String(record.stage).indexOf(value) === 0,
                        },
                        {
                            title: "操作",
                            align: "center",
                            fixed: 'right',
                            width: '20vw',
                            scopedSlots: {
                                customRender: "SerialNumber"
                            }
                        }],
                }
            },
            vipVisible: false,
            userId: sessionStorage.getItem('userId'),

        };

    },
    methods: {
        //获取车辆在线信息
        online() {
            getLastLocation({ unitId: sessionStorage.getItem('unitId') }).then(res => {
                this.getVehListInnerDev();
                this.phoneVehicleStatus.list.onlineResults = res.data;
            });
        },
        //车辆在线颜色
        trColor(record, index) {
            let className = 'light-row';
            if (record.online === 1) {
                className = 'bul-row'
            };
            return className;
        },
        transDate() {
            for (let i = 0; i < this.phoneVehicleStatus.list.onlineResults.length; i++) {
                for (let j = 0; j < this.phoneVehicleStatus.list.results.length; j++) {
                    if (this.phoneVehicleStatus.list.onlineResults[i].imei === this.phoneVehicleStatus.list.results[j].imei) {
                        this.phoneVehicleStatus.list.results[j].online = this.phoneVehicleStatus.list.onlineResults[i].online
                    }
                }
            }
            if(!this.phoneVehicleStatus.list.firstLoad){
                this.phoneVehicleStatus.list.firstLoad = true;
                this.phoneVehicleStatus.list.totalResult = this.phoneVehicleStatus.list.results.filter(item => {return item.vehType.charAt(0) == 'C'});
            }else{
                this.phoneVehicleStatus.list.totalResult = this.phoneVehicleStatus.list.results;
            }
      
        },
        onChange(pagination, filters, sorter) {
            if(filters.vehType){
                this.phoneVehicleStatus.list.totalResult = this.phoneVehicleStatus.list.results;
            }
          },
        // 获取车辆列表
        getVehListInnerDev() {
            let query = this.phoneVehicleStatus.list.query;
            getVehListInnerDev(query).then(res => {
                this.phoneVehicleStatus.list.results = res.data.filter(item => { return item.devId && item.imei });
                this.transDate();
            });
        },
        getcheck(val, record) {
            let params = { devId: record.devId, devNum: record.unifyCode, imei: record.imei, trainType: record.trainType ? 0 : 1 }
            toggleTrainType(params).then(res => {
                if (res) {
                    this.$message.success('状态转换成功');
                    this.getVehListInnerDev();

                } else {
                    this.$message.success('状态转换成功');
                    this.getVehListInnerDev();
                }
            })
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            const veh = "veh";
            this.phoneVehicleStatus.bindResult.radResult = [...selectedRowKeys];
            selectedRows.map(item => {
                this.phoneVehicleStatus.bindResult.selectedInfo.push(item);
                this.phoneVehicleStatus.list.tresults = this.phoneVehicleStatus.bindResult.selectedInfo;
            });
            const r = this.phoneVehicleStatus.bindResult.selectedInfo.reduce((all, next) => all.some((atom) => atom[veh] == next[veh]) ? all : [...all, next], []);
            this.phoneVehicleStatus.bindResult.selectedInfo = r.filter(item => {
                return this.phoneVehicleStatus.bindResult.radResult.some((e) => {
                    return item.veh === e;
                });
            });
        },
        //转换为培训 =>0培训
        changeTrain(type) {
            this.phoneVehicleStatus.list.tresults = [...this.phoneVehicleStatus.list.tresults,];
            for (let i = 0; i < this.phoneVehicleStatus.list.tresults.length; i++) {
                // console.log(this.wxHome.list.tresults[i])
                let query = {
                    devId: this.phoneVehicleStatus.list.tresults[i].devId,
                    devNum: this.phoneVehicleStatus.list.tresults[i].unifyCode,
                    imei: this.phoneVehicleStatus.list.tresults[i].imei,
                    trainType: type
                }
                this.toggleTrainType(query,i);
            }
        },
        //转换为考试 =>1考试
        toggleTrainType(query,i) {
            toggleTrainType(query).then(res => {
                if (!res.result) {
                    if(i == this.phoneVehicleStatus.list.tresults.length - 1){
                        this.$message.success("状态转换成功");
                        this.phoneVehicleStatus.list.tresults = [];
                        this.phoneVehicleStatus.bindResult.radResult = [];
                        this.getVehListInnerDev(); 
                    }

                } else {
                    this.$message.info(res.message);
                }
            });
        },
        // vip查询列表
        queryvipManage(queryBy) {
            let querys = this.phoneVehicleStatus.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getVipCount(querys).then((res) => {
                this.phoneVehicleStatus.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    // console.log('hcaxun',querys);
                    getVipList(querys).then((res) => {
                        this.phoneVehicleStatus.list.vipResults = res.data;
                    });
                } else {
                    this.phoneVehicleStatus.list.vipResults = [];
                    this.phoneVehicleStatus.list.total = 0;
                }
            });
        },
        showDrawer() {
            this.vipVisible = true;
            this.queryvipManage();
        },
        onClose() {
            this.vipVisible = false
        },
        // 审核函数isPass true 通过 false否决 isAll代表是一键通过还是一键否决 index数组长度
        isValid(record, isPass, isAll, index) {
            const { phone, unitId, valid, validUserId } = record;
            let params = { phone, unitId, valid, validUserId }
            isPass ? params.valid = 1 : params.valid = 2;
            validVip(params).then(res => {
                if (res.message.length) {
                    this.$message.error(res.message);
                } else {
                    let querys = this.phoneVehicleStatus.list.query;
                    this.$store.dispatch("StoreDataQuery/setQueryByNews", querys)
                    if (isPass && !isAll) {
                        this.queryvipManage();
                        this.$message.success('已通过');
                    } else if (!isPass && !isAll) {
                        this.queryvipManage();
                        this.$message.success('已否决');
                    } else if (isPass && isAll && index + 1 === this.phoneVehicleStatus.selectedKeys.length) {
                        this.queryvipManage();
                        this.$message.success('全部通过');
                    } else if (!isPass && isAll && index + 1 === this.phoneVehicleStatus.selectedKeys.length) {
                        this.queryvipManage();
                        this.$message.success('全部否决');
                    }
                }
            });
        },
        // 判断是否选中
        validVip(record, isPass) {
            this.isValid(record, isPass, false);
        },
        changeColor() {
            $('body').css("cssText", "background-color:#fff!important");
        }
    },
    computed: {
        hasSelected() {
            return this.phoneVehicleStatus.bindResult.radResult > 0
        }
    },
    created() {
        this.isFav();
    },
    activated() {
        this.isFav();
    },
    mounted() {
        this.changeColor();
        // 获取用户菜单
        getUserMenu({ userId: sessionStorage.getItem('userId') }).then(res => {
            const userMenus = res.data
            let veSId = userMenus.filter(item => { return item.menuName == 'vehicleStatus' }).length?userMenus.filter(item => { return item.menuName == 'vehicleStatus' })[0].menuId:''
            let veStatus = userMenus.filter(item => { return item.parentId == veSId })
            let veMId = userMenus.filter(item => { return item.menuName == 'vehicleMonitoring' }).length?userMenus.filter(item => { return item.menuName == 'vehicleMonitoring' })[0].menuId:''
            let veMoni = userMenus.filter(item => { return item.parentId == veMId })
            if (veStatus.length<1 && veMoni.length<1) {
                alert('没有权限')
                this.$store.dispatch('user/loginOut');
                this.$router.push({ path: '/login' });
            } else {
                this.$store.dispatch("StoreDataQuery/setQueryBySchool", { 'unitId': sessionStorage.getItem('unitId') }).then(res => {
                    this.phoneVehicleStatus.driverList = res.data;
                    this.online();
                });
            }  
        })
    },
};