import {
    getAllocateLogList
} from "@/service/businessData/index";
export default {
    name: "devControLog",
    data() {
        return {
            devControLog: {
                list: {
                    query: {
                        begin:this.$moment().format('YYYYMMDD'),
                        end: this.$moment().add('1','days').format('YYYYMMDD'),
                        eId:sessionStorage.getItem('eId'),
                    },
                    total: 0,
                    results:[]
                },
                daterange: [this.$moment(),this.$moment().add('1','days')],
                columns: [{
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                    },
                    {
                        title: "日期",
                        dataIndex: "orderDate",
                        customRender: (item => {
                            item = String(item);
                            return `${item.substr(0,4)}-${item.substr(4,2)}-${item.substr(6,2)}`;
                        })
                    },
                    {
                        title: "车牌号",
                        dataIndex: "newVeh",
                    },
                    {
                        title: "原车号",
                        dataIndex: "VehNo",
                    },
                    {
                        title: "调配后车号",
                        dataIndex: "newVehNo",
                    },{
                        title: "车型",
                        dataIndex: "vehType",
                    },
                    {
                        title: "科目",
                        dataIndex: "stage",
                    },
                    {
                        title: "增加分钟数",
                        dataIndex: "trainAdd",
                    },
                    {
                        title: "下发时间",
                        dataIndex: "inTime",
                        customRender: (item => {
                            return this.transForm(item);
                        })
                    },
                    {
                        title: "操作人",
                        dataIndex: "NickName",
                    }]
            },
            userArr: [],
            excelName: ['业务调配日志'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId'),
        }
    },
    methods: {
        getAllocateLogList() {
            let querys = this.devControLog.list.query;
            getAllocateLogList(querys).then(res=>{
                this.devControLog.list.results=[...res.data];
                this.devControLog.list.results =  this.devControLog.list.results.map((item, index) =>{
                    item.id = index;
                    return item
                })
                this.resetExport();
                this.selName = [`查询日期: ${this.devControLog.list.query.begin}-${this.devControLog.list.query.end}`," "," "," "," "," "," "," "," "," "," "];
                let excelsource= this.comexport(this.devControLog.columns,this.devControLog.list.results)
                this.excelData=[... this.excelData,...excelsource];
            })
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        }
    },
    created() {
      
    },
    mounted() {
        this.getAllocateLogList();
        this.isFav();
    },
    activated(){
        this.isFav();
    }
};