import {
  getvehList,
  getvehCount,
  addveh,
  modifyveh,
  delveh,
  getControlLog
} from "@/service/resManagement/index";
import {
  getStarList,
} from "@/service/costSet/index";
export default {
  //新增订单
  name: "testCarManagement",
  data() {
    return {
      //编辑页面数据
      testCarManagement: {
        list: {
          query: {
            vehType: '',
            starId:-1,
            stage: '',
            vehNo: '',
            veh: '',
            status: '',
            unitId:sessionStorage.getItem('unitId'),
            pageIndex: 1,
            pageSize: 10,
            vehId: '',
            userId: sessionStorage.getItem('userId'),
            tea:'',
          },
          total: 0,
          results: [],
          logresults:[]
        },
        modifyTrain: false,
        controlLogVisible:false,
        modalTitle: '',
        optionItem: '',
      },
       // 星级费用列表
       starFee:{
        list:[],
        serchList:[],
        modifyList:[]
    },
      basicForm: this.$form.createForm(this),
      userId:sessionStorage.getItem('userId'),
        //控制日志表头
        logColumns:[
            {
              title:"序号",
                scopedSlots:{
                    customRender:"SerialNumber"
                }
            },
            {
                title:"车牌号",
                dataIndex:"veh"
            },{
                title:"车号",
                dataIndex:"vehNo"
            },
            {
              title:"命令",
              dataIndex:"cmd"
            },
            {
              title:"命令内容",
              dataIndex:"cmdContent"
            },
            {
              title:"下发时间",
              dataIndex:"inTime",
                customRender: (item => {
                    return this.transForm(item);
                })
            },
            {
              title:"操作人",
              dataIndex:"nickname"
            }],
      //表头
      columns: [
        {
          width: 60,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        }, {
          title: "车牌号",
          dataIndex: "veh",
        },
        {
          title: "科目",
          dataIndex: "stage",
        },
        {
          title: "车型",
          dataIndex: "vehType",
        },
        {
          title: "厂牌",
          dataIndex: "factory",
        },
        {
          title: "车辆状态",
          dataIndex: "status",
          customRender: (item) => {
            return this.$enums.examinationCarStatus[item]
          }
        },
        {
          title: "星级",
          dataIndex: "starId",
          customRender: (item) => {
            return item?this.$enums.starClass[item]:'暂无';
          }
        }, {
          title: "车号",
              dataIndex: "vehNo",
        },
          {
              title: "安全员",
              dataIndex: "name",
          },
        {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['考试车管理'],
      selName:[],
      excelData:[],
    };
  },
  methods: {
    // 查询列表
    queryTestCar(queryBy) {
      let querys = this.testCarManagement.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getvehCount(querys).then((res) => {
        this.testCarManagement.list.total =res.data.rowCount;
        if (res.data.rowCount) {
          getvehList(querys).then((res) => {
            // console.log('res',res)
            this.$nextTick(() => {
              this.testCarManagement.list.results = res.data;
            })
              // console.log(res);
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          this.testCarManagement.list.results = [];
        }
      }).catch((err) => {})
    },
    getDate(){
      this.resetExport();
      let querys = this.testCarManagement.list.query;
      let serch ={...querys};
      serch.pageIndex = 1;
      serch.pageSize = this.testCarManagement.list.total + 100;
      getvehList(serch).then(res =>{
        this.selName = [`车牌号：${this.testCarManagement.list.query.veh}`, `车型: ${this.$rformat.methods.r_driverCarType(this.testCarManagement.list.query.vehType)}`, `星级: ${this.$rformat.methods.r_starClass(this.testCarManagement.list.query.starId)}`,`科目: ${this.$rformat.methods.r_projectItem(this.testCarManagement.list.query.stage)}`, `车号: ${this.testCarManagement.list.query.vehNo}`, " ", " ", " ", " "];
        let excelsource = this.comexport(this.columns, res.data);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
      })
    },
    // 修改显示数据
    modifyData(record) {
      let querys = this.testCarManagement.list.query;
      this.testCarManagement.modifyTrain = true;
      querys.vehId = record.vehId;
      if (record) {
        this.testCarManagement.modalTitle = '修改车辆数据';
        this.testCarManagement.optionItem = 'modifyData';
        this.$nextTick(() => {
          this.basicForm.setFieldsValue({
            veh: record.veh,
            vehNo: record.vehNo,
            factory: record.factory,
            vehType: record.vehType,
            stage: record.stage,
            starId: record.starId,
            status: record.status
          });
        })
      } else {
        this.testCarManagement.modalTitle = '增加车辆数据';
        this.testCarManagement.optionItem = 'addData';
      }
    },
    // 模态框确定事件
    confirmRevision() {
      let querys = this.testCarManagement.list.query;
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          values.unitId = querys.unitId;
          values.vehId = querys.vehId;
          this.saveDate(values);
          this.handleFormFieldsReset(this.basicForm);
        }
      });
    },
    // 保存数据
    saveDate(values) {
      if (this.testCarManagement.optionItem === 'addData') {
        values.status = 0;
        addveh(values).then((res) => {
          if (res.message) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('新增成功');
          }
        }).catch((err) => {
          console.log('err', err);
        })
      } else {
        modifyveh(values).then((res) => {
          if (res.message) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('修改成功');
          }
        }).catch((err) => {
          console.log('err', err);
        })
      }
    },
    // 注销考试车
    delData(record) {
      delveh(record).then((res) => {
        if (res.message) {
          this.$message.error(res.message)
        } else {
          this.queryTestCar();
          this.$message.success('注销成功');
        }
      }).catch((err) => {
        console.log('err', err);
      })
    },
      //控制日志
      controlLog(record){
      let params={unitId:record.unitId,vehId:record.vehId,begin:this.$moment().subtract('days', 6).format('YYYY-MM-DD HH:mm'),end:this.$moment().format('YYYY-MM-DD HH:mm')}
          getControlLog(params).then((res)=>{
             this.testCarManagement.list.logresults=[...res.data];
          })
          this.testCarManagement.controlLogVisible=true;
      },
    // 重置信息
    resetInfo() {
      this.queryTestCar();
      this.testCarManagement.modifyTrain = false;
      this.handleFormFieldsReset(this.basicForm);
    },
    resetExport(){
      this.selName=[];
      this.excelData=[];
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTestCar();
    this.starFee.list=JSON.parse(sessionStorage.getItem('starCost'));
    let arr=this.starFee.list.filter(item=>{return item.starType===1});
    // console.log('arr',arr)
    let arrObj=[{'starName':'全部','starId':-1}];
    let arrObj2=[{'starName':'无','starId':0}]
    this.starFee.serchList=[...arr,...arrObj];
    this.starFee.modifyList=[...arr,...arrObj2];
    this.setQueryByAdvanced(this.testCarManagement.list.query);
     this.isFav();
  },
  activated() {
    this.starFee.list=JSON.parse(sessionStorage.getItem('starCost'));
    // console.log('type',typeof this.starFee.list)
    let arr=this.starFee.list.filter(item=>{return item.starType===1});
    // console.log('arr',arr)
    let arrObj=[{'starName':'全部','starId':-1}];
    let arrObj2=[{'starName':'无','starId':0}]
    this.starFee.serchList=[...arr,...arrObj];
    this.starFee.modifyList=[...arr,...arrObj2];
     this.isFav();
  },
};