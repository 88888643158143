import {
    postLocalStuTrainDetail
}
    from "@/service/businessData/index";
export default {
    name: "stuTrain",
    data() {
        return {
            stuTrain: {
                list: {
                    query: {
                        begin: this.$moment().add(0, 'day').format('YYYYMMDD'),
                        end: this.$moment().add(1, 'day').format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId'),
                        stuCertId: '',
                        phone: ''
                        // unitId:sessionStorage.getItem('unitId'),
                        // userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: [],
                },
                daterange: [this.$moment().add(0, 'day'), this.$moment().add(0, 'day')],
                columns: [{
                    width: 75,
                    title: "序号",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                },
                {
                    title: "学员姓名",
                    dataIndex: "stuName",
                },
                {
                    title: "学员身份证号",
                    dataIndex: "stuCertId",
                },
                    {
                        title: "电话号码",
                        dataIndex: "phone",
                    },
                {
                    title: "科目",
                    dataIndex: "stage",
                },
                {
                    title: "车号",
                    dataIndex: "vehNo",
                },
                {
                    title: "车牌号",
                    dataIndex: "veh",
                },
                {
                    title: "开始时间",
                    dataIndex: "begin",
                    customRender: (item) => {
                        return `${parseInt(item)}分钟`
                    }
                    // customRender: (item) => {
                    //     if (!item) {
                    //         return '无签到时间';
                    //     } else {
                    //         return item
                    //     }
                    // }
                },
                {
                    title: "结束时间",
                    dataIndex: "end",
                    customRender: (item) => {
                        if (!item) {
                            return '无签到时间';
                        } else {
                            return item
                        }
                    }
                },
                {
                    title: "教练姓名",
                    dataIndex: "teaName",
                },
                {
                    title: "教练身份证号",
                    dataIndex: "teaCertId",
                },
                {
                    title: "培训时长/分钟",
                    dataIndex: "trainTime",
                    customRender: (item) => {
                        return `${parseInt(item)}分钟`
                    }
                },
                ]
            },
            excelName: ['本校学员培训明细'],
            selName: [],
            excelData: [],
            userId: sessionStorage.getItem('userId')
        }
    },
    methods: {
        querysafer() {
            let querys = this.stuTrain.list.query;
            postLocalStuTrainDetail(querys).then(res => {
                this.stuTrain.list.results = [...res.data];
                this.stuTrain.list.results = this.stuTrain.list.results.sort(this.compareKey());
                this.stuTrain.list.results = this.stuTrain.list.results.filter(item => {
                    return item.stuName && item.teaName
                });
                this.stuTrain.list.results = this.stuTrain.list.results.map(item => {
                    item.begin =item.begin?item.begin:'无签到时间';
                    item.end =item.end?item.end:"无签退时间"
                    return item
                });
                this.resetExport();
                this.selName = [`身份证号：${this.stuTrain.list.query.stuCertId}`,`电话号码：${this.stuTrain.list.query.phone}`,`查询日期: ${this.stuTrain.list.query.begin}-${this.stuTrain.list.query.end}`, " ", " ", " ", " ", " "," ", " ", " ", " ", " "];
                let excelsource = this.comexport(this.stuTrain.columns, this.stuTrain.list.results)
                this.excelData = [... this.excelData, ...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.stuTrain.daterange[0]).format('YYYY-MM-DD')}至${this.$moment(this.stuTrain.daterange[1]).format('YYYY-MM-DD')}`, " ", " ", " ", " ", " ", " ", " ", " "," ", " ", " ", " "]);
                this.stuTrain.list.results = this.stuTrain.list.results.map((item, index) => {
                    return { ...item, ...{ id: index } }
                })
            })
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        getDate() {
            this.$nextTick(() => {
                this.$refs.export.getExport(this.selName, this.excelName, this.excelData);
            })
        },
        getDateStr(e) {
            this.stuTrain.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.stuTrain.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.stuTrain.daterange = e;
        },
        // changeDate(item) {
        //     if(item){
        //         let startT = item.lastIndexOf('T');
        //         var result =`${item.substring(0, startT)} ${item.substring(startT+1, item.lastIndexOf('.'))}`;
        //         return result;
        //     }
        // },
        // 数组排序
        compareKey() {
            return function (obj1, obj2) {
                if (obj1['teaCertId'] < obj2['teaCertId']) {
                    return -1;
                } else if (obj1['teaCertId'] === obj2['teaCertId']) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },

    },
    created() {
    },
    mounted() {
        this.isFav();
        this.querysafer();
    },
    activated() {
        this.isFav();
    }
};
