import {
  allocateVeh,
  getInCompleteWork,
  getTodayWork,
  getVehListInnerDev,
  sendText,
  toggleFuncStart,
  toggleTrainType,
  getAdminCode,
  setAdminCode,
  getLockAndType,
  getLastLocation
} from "@/service/vehicleStatus/index";
import {
  getAuditList,
} from "@/service/charge/index";
import {
  getTeaList,
  getCount,
  getControlLog
} from "@/service/resManagement/index";
export default {
  name: "vehicleStatus",
  data() {
    return {
      vehicleStatus: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 500,
            //stage 默认0为全部
            stage: 0,
            //starId 默认-1为全部
            starId: -1,
            status: '',
            unitId: sessionStorage.getItem('unitId'),
            veh: '',
            vehNo: '',
            vehType: '',
          },
          total: 0,
          results: [],
          logresults: [],
          stage2res: [],
          stage3res: [],
          LockAndType: [],
          LockretDate: []
        },
        stage2: {
          checkedList: [],
          indeterminate: false,
          checkAll: false,
          plainOptions: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
        },
        stage3: {
          checkedList: [],
          indeterminate: false,
          checkAll: false,
          plainOptions: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
        },
        modal: {
          deployvisible: false,
          viewvisible: false,
          screen: false,
          log: false,
          pwd: false,
          sendText: {}
        },
        //控制日志表
        logColumns: [
          {
            title: "序号",
            scopedSlots: {
              customRender: "SerialNumber"
            }
          },
          {
            title: "车牌号",
            dataIndex: "veh"
          }, {
            title: "车号",
            dataIndex: "vehNo"
          },
          {
            title: "命令",
            dataIndex: "cmd"
          },
          {
            title: "命令内容",
            dataIndex: "cmdContent"
          },
          {
            title: "下发时间",
            dataIndex: "inTime",
            customRender: (item => {
              return this.transForm(item);
            })
          },
          {
            title: "操作人",
            dataIndex: "nickname"
          }],
        todayWork: {
          total: 0,
          columns: [{
            width: 75,
            title: "序号",
            align: "center",
            scopedSlots: {
              customRender: "SerialNumber"
            },
          }, {
            title: "业务编号",
            dataIndex: "stageMark",
            width: 150,
          }, {
            title: "轮次",
            dataIndex: "turnCnt",
            width: 150,
          }, {
            title: "科目",
            dataIndex: "stage",
            width: 150,
          }, {
            title: "联系电话",
            dataIndex: "phone",
            width: 150,
          },
          {
            title: "购买时长",
            dataIndex: "costTime",
            customRender: item => {
              return `${item / 60}小时`;
            },
            width: 150,
          },
          {
            title: "业务状态",
            dataIndex: "status",
            customRender: item => {
              return this.$enums.stageStatus[item];
            },
            width: 150,
          }
          ],
          results: [],
          item: {}
        },
        allocateVeh: {
          total: 0,
          columns: [
            {
              title: "操作",
              dataIndex: "option",
              width: 75,
              scopedSlots: {
                customRender: "option"
              },
            },
            {
              title: "增加分钟",
              dataIndex: "addSec",
              width: 120,
              align: 'center',
              scopedSlots: {
                customRender: "addSec"
              },
            },
            {
              title: "业务编号",
              dataIndex: "stageMark",
              width: 100,
            }, {
              title: "轮次",
              dataIndex: "turnCnt",
              width: 75,
            }, {
              title: "科目",
              dataIndex: "stage",
              width: 75,
            }, {
              title: "联系电话",
              dataIndex: "phone",
              width: 100,
            }, {
              title: "购买时长",
              dataIndex: "costTime",
              width: 100,
              customRender: item => {
                return `${item / 60}小时`;
              },
            },
            {
              title: "已训",
              dataIndex: "alxun",
              width: 75,
            },
            {
              title: "未训",
              dataIndex: "rexun",
              width: 75,
            }, {
              title: "业务状态",
              dataIndex: "status",
              width: 100,
              customRender: item => {
                return this.$enums.stageStatus[item];
              },
            },

          ],
          results: [],
          arr: [],
          item: {},
          newVehNo: '',
          newStage: 0,
          // 调配数组
          changeRrr: [],
          // 未完成数组
          inCompleteArr: [],
          checkTea: false,
          allocate: {
            newTeaId: 0,
            newVehId: ''
          }
        },
        seltable: {
          columns: [{
            width: 75,
            title: "序号",
            align: "center",
            scopedSlots: {
              customRender: "SerialNumber"
            },
          },
          {
            title: "业务编号",
            dataIndex: "stageMark",
            width: 100,
          }, {
            title: "轮次",
            dataIndex: "turnCnt",
            width: 75,
          }, {
            title: "科目",
            dataIndex: "stage",
            width: 75,
          }, {
            title: "联系电话",
            dataIndex: "phone",
            width: 100,
          }, {
            title: "购买时长",
            dataIndex: "costTime",
            width: 100,
            customRender: item => {
              return `${item / 60}小时`;
            },
          }, {
            title: "业务状态",
            dataIndex: "status",
            width: 100,
            customRender: item => {
              return this.$enums.stageStatus[item];
            },
          },
          ],
          results: [],
          stageParam: '',
        },
        changeState: [],
        // 要操作的项
        optionTestArr: [],
        optionComArr: [],
        onlineNum: 0,
        onlineResult: [],
        progress: {
          percent: 0,
          showProgress: false,
          step: 0,
          spinning: false,
          err: [],
        }
      },
      saferManagement: {
        list: {
          query: {
            name: '',
            phone: '',
            starId: -1,
            pageIndex: 1,
            pageSize: 500,
            unitId: sessionStorage.getItem('unitId'),
            teaId: '',
            vehType: '',
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: []
        }
      },
      pwd: {
        Code: '',
        params: {}
      },
      basicForm: this.$form.createForm(this),
      // 异常数组
      businessAudit: {
        list: {
          query: {
            applyDate: this.$moment().format('YYYY-MM-DD 00:00:00'),
            applyDateEnd: this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
            pageIndex: 1,
            pageSize: 500,
            unitId: sessionStorage.getItem('unitId'),
            stageMark: 0,
            auditStatus: 0,
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: [],
          cartotal: 0
        },
        daterange: [],
      },
      // 搜索定位数组
      serchVehNo: {
        arr: [],
        stage3Arr: []
      },
      remnant: 50,
      btn: {
        disabled: false,
        number: 30
      },
      saferSorce: [],
      carSorce: [],
      timeOut: {
        times: 0
      },
      searchBarFixed:false,
      btnRole:[]
    }
  },
  methods: {
    // 获取车辆列vehicleStatus.list.results表
    getVehListInnerDev() {
      let query = this.vehicleStatus.list.query;
      getVehListInnerDev(query).then(res => {
        this.queryAudit();
        this.vehicleStatus.list.cartotal = res.data.length;
        this.vehicleStatus.list.results = res.data.sort(this.compareKey()).filter(item => { return item.devId && item.imei });
        if (!this.vehicleStatus.list.LockAndType.length) {
          this.vehicleStatus.list.LockAndType = res.data.sort(this.compareKey());
        }
        this.changeRqData();
      });
    },
    // 获取设备油电和状态
    getLockAndType() {
      this.vehicleStatus.list.LockAndType.map((item, index) => {
        let query = { devNum: item.unifyCode ? item.unifyCode : '', imei: item.imei ? item.imei : '' }
        getLockAndType(query).then(res => {
          this.vehicleStatus.list.LockretDate.push(res.data);
          this.vehicleStatus.list.LockretDate = this.vehicleStatus.list.LockretDate.filter(item => {
            return item;
          });
          let obj = {};
          this.vehicleStatus.list.LockretDate = this.vehicleStatus.list.LockretDate.reduceRight((cur, next) => {
            obj[next.imei] ? "" : obj[next.imei] = true && cur.push(next);
            return cur;
          }, []);
          if (index === this.vehicleStatus.list.LockAndType.length - 1) {
            this.changeRqData();
            // 最后一次修改，这段代码原位置changeRqData()函数
            this.vehicleStatus.list.LockAndType = [];
          }
        });
      });

    },
    // 获取异常状态列表
    queryAudit(queryBy) {
      let querys = this.businessAudit.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getAuditList(querys).then(res => {
        this.businessAudit.list.results = [...res.data];
        this.vehicleStatus.list.LockretDate = [];
        this.changeRqData();
        this.getLockAndType();
      });
    },
    //车号和科目
    carData(value) {
      this.vehicleStatus.seltable.stageParam = value;
      let arr = [];
      if (!value.trim().length) {
        arr = this.vehicleStatus.list.results
      } else {
        arr = this.vehicleStatus.list.results.filter(item => {
          if (item.vehNo == value) {
            return item;
          }
        });
      };
      if (value.indexOf('/') != -1) {
        this.vehicleStatus.allocateVeh.newStage = value.substr(value.length - 1);
        this.vehicleStatus.allocateVeh.newVehNo = value.split("号")[0];
      } else {
        this.vehicleStatus.allocateVeh.newStage = 0;
        this.vehicleStatus.allocateVeh.newVehNo = value;
      }

      this.carSorce = arr.map(item => {
        return `${item.vehNo}号/科目${item.stage}`
      });
    },
    // 组合数据
    changeRqData() {
      // console.log('come in')
      for (let i = 0; i < this.businessAudit.list.results.length; i++) {
        for (let j = 0; j < this.vehicleStatus.list.results.length; j++) {
          if (this.businessAudit.list.results[i].vehId === this.vehicleStatus.list.results[j].vehId) {
            this.vehicleStatus.list.results[j].auditStatus = 0;
          }
        }
      }
      for (let i = 0; i < this.vehicleStatus.list.LockretDate.length; i++) {
        for (let j = 0; j < this.vehicleStatus.list.results.length; j++) {
          if (this.vehicleStatus.list.LockretDate[i].imei === this.vehicleStatus.list.results[j].imei) {
            this.vehicleStatus.list.results[j].trainType = this.vehicleStatus.list.LockretDate[i].MainKey;
            this.vehicleStatus.list.results[j].funcStart = this.vehicleStatus.list.LockretDate[i].LockStat
          }
        }
      }
      for (let i = 0; i < this.vehicleStatus.onlineResult.length; i++) {
        for (let j = 0; j < this.vehicleStatus.list.results.length; j++) {
          if (this.vehicleStatus.onlineResult[i].imei === this.vehicleStatus.list.results[j].imei) {
            this.vehicleStatus.list.results[j].online = this.vehicleStatus.onlineResult[i].online;
          }
        }
      }
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(item => {
        if (item.trainType === undefined) {
          item.trainType = 1
        }
        if (item.funcStart === undefined) {
          item.funcStart = 1
        }
        return { ...item, ...{ checkState: false } }
      });
      // this.vehicleStatus.list.result= this.vehicleStatus.list.result.sort(this.compareKey())
      this.vehicleStatus.list.stage2res = this.vehicleStatus.list.results.filter(item => {
        return item.stage === 2
      });
      this.vehicleStatus.list.stage3res = this.vehicleStatus.list.results.filter(item => {
        return item.stage === 3
      });

      // this.vehicleStatus.list.LockAndType=[];
    },
    // 数组排序
    compareKey() {
      return function (obj1, obj2) {
        if (obj1['vehNo'] < obj2['vehNo']) {
          return -1;
        } else if (obj1['vehNo'] === obj2['vehNo']) {
          return 0;
        } else {
          return 1;
        }
      };
    },
    // 选择车型
    stage2Change(checkedList) {
      this.vehicleStatus.stage2.indeterminate = !!checkedList.length && (checkedList.length < this.vehicleStatus.stage2.plainOptions.length)
      this.vehicleStatus.stage2.checkAll = checkedList.length === this.vehicleStatus.stage2.plainOptions.length
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(item => {
        if (checkedList.includes(item.vehType) && item.stage === 2) {
          item.checkState = true;
        } else if (item.stage === 2) {
          item.checkState = false;
        }
        return item;
      })
    },
    stage2CheckAllChange(e) {
      this.vehicleStatus.stage2.checkedList = e.target.checked ? this.vehicleStatus.stage2.plainOptions : [],
        this.vehicleStatus.stage2.indeterminate = false;
      this.vehicleStatus.stage2.checkAll = e.target.checked;
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(item => {
        if (e.target.checked && item.stage === 2) {
          item.checkState = true;
        } else if (item.stage === 2) {
          item.checkState = false;
        }
        return item;
      })
    },
    stage3Change(checkedList) {
      this.vehicleStatus.stage3.indeterminate = !!checkedList.length && (checkedList.length < this.vehicleStatus.stage3.plainOptions.length)
      this.vehicleStatus.stage3.checkAll = checkedList.length === this.vehicleStatus.stage3.plainOptions.length;
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(item => {
        if (checkedList.includes(item.vehType) && item.stage === 3) {
          item.checkState = true;
        } else if (item.stage === 3) {
          item.checkState = false;
        }
        return item;
      })
    },
    stage3CheckAllChange(e) {
      this.vehicleStatus.stage3.checkedList = e.target.checked ? this.vehicleStatus.stage3.plainOptions : [],
        this.vehicleStatus.stage3.indeterminate = false;
      this.vehicleStatus.stage3.checkAll = e.target.checked;
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(item => {
        if (e.target.checked && item.stage === 3) {
          item.checkState = true;
        } else if (item.stage === 3) {
          item.checkState = false;
        }
        return item;
      })
    },
    // 选中车牌号
    onChange(item, index) {
      this.vehicleStatus.list.results = this.vehicleStatus.list.results.map(e => {
        if (Object.is(e, item)) {
          e.checkState = !e.checkState;
        }
        return e;
      })
    },
    // 按钮全选转换  { 培训：trainType 1 } { 考试：trainType 0} { 油电开启：trainStart 0} { 油电开启：trainStart 1}
    changeAll(type, key) {
      this.vehicleStatus.changeState = this.vehicleStatus.list.results.filter(item => {
        return item.checkState;
      })

      if (!this.vehicleStatus.changeState.length) {
        this.$message.info('请选择需要转换的车辆');
        return;
      }
      let vehNoArr = [];
      let arr = this.vehicleStatus.changeState.filter(item => {
        return !item.devId || !item.imei
      });
      arr.map(item => {
        vehNoArr.push(item.vehNo);
      })
      if (vehNoArr.length) {
        this.$message.info(`以下这些车辆信息不全,无法转换状态,车号：${vehNoArr.join(',')}`)
      }
      if (type === 'trainType') {
        this.vehicleStatus.optionTestArr = this.vehicleStatus.changeState.filter(item => {
          return item.devId && item.imei
        });
        this.vehicleStatus.optionTestArr.map((e, index) => {
          return this.transTest(e, key, index)
        })
      } else {
        this.vehicleStatus.optionComArr = this.vehicleStatus.changeState.filter(item => {
          return item.devId && item.imei
        })
        this.vehicleStatus.optionComArr.map((e, index) => {
          return this.transStart(e, key, index);
        })
      }
    },
    //转为考试
    transType(e, option, index) {
      this.resetMsg(this.vehicleStatus.list.results.length);
      if (!e.devId || !e.imei) {
        this.$message.info('该车辆信息不全,请补全信息以后再转换');
        return;
      }
      option === "stu" ? this.transTest(e, 1, "single") : this.transTest(e, 0, "single");
    },
    //断油电
    compass(e, option, index) {
      this.resetMsg(this.vehicleStatus.list.results.length);
      if (!e.devId || !e.imei) {
        this.$message.info('该车辆信息不全,请补全信息以后再转换');
        return;
      }
      option === 'open' ? this.transStart(e, 1) : this.transStart(e, 0);
    },
    compasscal(index) {
      this.resetMsg(this.vehicleStatus.list.results.length);
    },
    popovercal(index) {
      this.resetMsg(this.vehicleStatus.list.results.length);
    },
    // 车辆培训/考试状态转换
    transTest(e, val, length) {
      this.vehicleStatus.list.LockAndType = [...this.vehicleStatus.list.LockAndType, ...[e]];
      let query = {
        devId: e.devId,
        devNum: e.unifyCode,
        imei: e.imei,
        trainType: val
      }
      toggleTrainType(query).then(res => {
        if (!res.result) {
          this.getVehListInnerDev();
          // if(length==="single"){
          //   this.getVehListInnerDev();
          // }
        } else {
          this.$message.info(res.message);
          this.vehicleStatus.progress.err.push(res)
        }
      });
      if (length === this.vehicleStatus.optionTestArr.length - 1) {
        this.$message.info("状态转换完成");
        // this.getVehListInnerDev();
        this.vehicleStatus.optionTestArr = [];
        this.vehicleStatus.stage2.checkAll = false;
        this.vehicleStatus.stage2.checkedList = [];
        this.vehicleStatus.stage3.checkAll = false;
        this.vehicleStatus.stage3.checkedList = [];
      }
    },
    // 车辆油电
    transStart(e, val, length) {
      this.vehicleStatus.list.LockAndType = [...this.vehicleStatus.list.LockAndType, ...[e]];
      let query = {
        devId: e.devId,
        devNum: e.unifyCode,
        imei: e.imei,
        start: val
      }
      toggleFuncStart(query).then(res => {
        if (!res.result) {
          // console.log('res.data', res.data);
          // this.$message.info('操作成功');
          this.getVehListInnerDev();
        } else {
          this.$message.info(res.message);
        }
      })
      if (length === this.vehicleStatus.optionComArr.length - 1) {
        this.$message.info("状态转换完成");
        // this.getVehListInnerDev();
        this.vehicleStatus.optionComArr = [];
        this.vehicleStatus.stage2.checkAll = false;
        this.vehicleStatus.stage2.checkedList = [];
        this.vehicleStatus.stage3.checkAll = false;
        this.vehicleStatus.stage3.checkedList = [];
      }
    },
    // 今日情况查看
    getTodayWork(query) {
      // console.log('query', query)
      this.vehicleStatus.todayWork.item = query;
      getTodayWork(query).then(res => {
        this.vehicleStatus.todayWork.results = res.data;
      }).catch(err => {
        console.log('err', err)
      })
    },
    controlLog(item) {
      let params = { unitId: item.unitId, vehId: item.vehId, begin: this.$moment().subtract('days', 6).format('YYYY-MM-DD HH:mm'), end: this.$moment().format('YYYY-MM-DD HH:mm') }
      getControlLog(params).then((res) => {
        this.vehicleStatus.list.logresults = [...res.data];
      })
    },
    //菜单
    transmenu(item, option, index) {
      // 隐藏小菜单
      this.vehicleStatus.modal.sendText.devNum = item.unifyCode;
      this.vehicleStatus.modal.sendText.imei = item.imei;
      this.resetMsg(this.vehicleStatus.list.results.length);
      if (option === 'deploy') {
        this.vehicleStatus.modal.deployvisible = true;
        this.getInCompleteWork(item);
      } else if (option === 'view') {
        this.vehicleStatus.modal.viewvisible = true;
        this.getTodayWork(item)
      } else if (option === 'msg') {
        this.vehicleStatus.modal.screen = true;
      } else if (option === 'log') {
        this.vehicleStatus.modal.log = true;
        this.controlLog(item)
      } else if (option === 'pwd') {
        this.pwd.params = { ...item };
        this.vehicleStatus.modal.pwd = true;
        this.getAdminCode(item);
      }
      // console.log('item,option', index)
    },
    // 获取本车当天未完成业务列表
    getInCompleteWork(item) {
      // console.log('itemitem',item)
      this.vehicleStatus.allocateVeh.item = item;
      getInCompleteWork(item).then(res => {
        // console.log('1000',res)
        this.vehicleStatus.allocateVeh.results = res.data.map(item => {
          return {
            ...item,
            ...{
              'trainAdd': 0
            }
          }
        });;
        this.vehicleStatus.allocateVeh.arr = res.data.map(item => {
          return {
            ...item,
            ...{
              'trainAdd': 0
            }
          }
        });
        // console.log('res', res)
      }).catch(err => {
        console.log('err', err)
      })
    },
    // 查看此车剩余业务量
    getInComplete() {
      this.vehicleStatus.allocateVeh.inCompleteArr = [];
      if (!this.vehicleStatus.allocateVeh.newVehNo.length) {
        // console.log('come in')
        this.$message.info('请先输入车号');
        return;
      }
      let arr = this.IsExsitveh();
      if (this.vehicleStatus.allocateVeh.newStage !== 0) {
        arr = arr.filter(item => {
          return item.vehNo == this.vehicleStatus.allocateVeh.newVehNo && item.stage == this.vehicleStatus.allocateVeh.newStage;
        })
      }
      if (!arr.length) {
        this.vehicleStatus.allocateVeh.allocate.newVehId = '';
        this.$message.info('此车号不存在，请重新输入');
        return;
      }
      for (let item of arr) {
        this.vehicleStatus.allocateVeh.allocate.newVehId = item.vehId;
        const query = {
          'unitId': sessionStorage.getItem('unitId'),
          'vehId': item.vehId
        }
        getInCompleteWork(query).then(res => {
          this.vehicleStatus.allocateVeh.inCompleteArr = [...this.vehicleStatus.allocateVeh.inCompleteArr, ...res.data];
        }).catch(err => {
          console.log('err', err)
        })
      }
      // console.log('heihei', this.vehicleStatus.allocateVeh.newVehNo.length);
    },
    // // 查看此车剩余业务量
    // getInComplete() {
    //   if (!this.vehicleStatus.allocateVeh.newVehNo.length) {
    //     // console.log('come in')
    //     this.$message.info('请先输入车号');
    //     return;
    //   }
    //   let item = this.IsExsitveh();
    //   if (!item.length) {
    //     this.vehicleStatus.allocateVeh.allocate.newVehId='';
    //     this.$message.info('此车号不存在，请重新输入');
    //     return;
    //   }
    //   this.vehicleStatus.allocateVeh.allocate.newVehId = item[0].vehId;
    //   const query = {
    //     'unitId': sessionStorage.getItem('unitId'),
    //     'vehId': item[0].vehId
    //   }
    //   getInCompleteWork(query).then(res => {
    //     this.vehicleStatus.allocateVeh.inCompleteArr = res.data;
    //     // console.log('res', this.vehicleStatus.allocateVeh.inCompleteArr)
    //   }).catch(err => {
    //     console.log('err', err)
    //   })
    //   // console.log('heihei', this.vehicleStatus.allocateVeh.newVehNo.length);
    // },
    // // 查看车号是否存在
    // IsExsitveh(){
    // return  this.vehicleStatus.list.results.filter(item => {
    //     return item.vehNo === Number(this.vehicleStatus.allocateVeh.newVehNo)
    //   })
    // },
    IsExsitveh() {
      let arr = [];
      this.vehicleStatus.list.results.map(item => {
        if (item.vehNo === Number(this.vehicleStatus.allocateVeh.newVehNo)) {
          arr.push(item);
        }
      });
      return arr;
    },
    // 只查看异常状态
    error(e) {
      if (e.target.checked) {
        this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.results.filter(item => {
          return item.status === 2;
        })
      } else {
        this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.results;
      }
    },
    // 是否调配安全员
    changeTea(e) {
      this.vehicleStatus.allocateVeh.checkTea = e.target.checked;
    },
    // 增加时长
    addSec(costTime, record) {
      this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.arr.map(item => {
        if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
          return {
            ...item,
            ...{
              'trainAdd': costTime
            }
          }
        } else {
          return item;
        }
      });
      this.vehicleStatus.allocateVeh.changeRrr = this.vehicleStatus.allocateVeh.changeRrr.map(item => {
        if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
          return {
            ...item,
            ...{
              'trainAdd': costTime
            }
          }
        } else {
          return item;
        }
      });
    },
    selbox(e, record) {
      if (e.target.checked) {
        this.vehicleStatus.allocateVeh.changeRrr.push(record);
      } else {
        this.vehicleStatus.allocateVeh.changeRrr = this.vehicleStatus.allocateVeh.changeRrr.filter(item => {
          return !record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt
        })
      }
    },
    // 重置信息
    resetMsg(number) {
      for (let i = 0; i <= number; i++) {
        if (this.$refs.menu) {
          if (this.$refs.menu[i]) {
            this.$refs.menu[i].visible = false;
            this.$refs.popover[i].visible = false;
            this.$refs.compass[i].visible = false;
          }
        }
        if (this.$refs.menu2) {
          if (this.$refs.menu2[i]) {
            this.$refs.menu2[i].visible = false;
            this.$refs.popover2[i].visible = false;
            this.$refs.compass2[i].visible = false;
          }
        }
      }
    },
    // 显示侧边
    disCard(index, popover) {
      if (popover === "popover") {
        if (this.$refs.popover[index].visible) {
          this.$refs.popover[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.popover[index].visible = true;
        }
      } else {
        if (this.$refs.popover2[index].visible) {
          this.$refs.popover2[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.popover2[index].visible = true;
        }
      }
    },
    // 显示油电信息
    discompass(index, compass, item) {
      if (!item.trainType) {
        this.$message.info('此车为考试状态,请先转换为培训状态！');
        return;
      }
      if (compass === "compass") {
        if (this.$refs.compass[index].visible) {
          this.$refs.compass[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.compass[index].visible = true;
        }

      } else {
        if (this.$refs.compass2[index].visible) {
          this.$refs.compass2[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.compass2[index].visible = true;
        }
      }
      // console.log('index,compass', index, compass)
    },
    // 显示菜单信息
    menu(index, menu) {
      if (menu === 'menu') {
        if (this.$refs.menu[index].visible) {
          this.$refs.menu[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.menu[index].visible = true;
        }

      } else {
        if (this.$refs.menu2[index].visible) {
          this.$refs.menu2[index].visible = false;
        } else {
          this.resetMsg(this.vehicleStatus.list.results.length);
          this.$refs.menu2[index].visible = true;
        }
      }
    },
    // 发送信息
    sendText() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          // console.log('come in')
          this.vehicleStatus.modal.sendText.message = values.message;
          const query = this.vehicleStatus.modal.sendText;
          sendText(query).then(res => {
            if (!res.result) {
              this.vehicleStatus.modal.screen = false;
              this.remnant = 50;
              this.handleFormFieldsReset(this.basicForm);
            }
          }).catch(err => {
            console.log('err', err)
          })
        }
      });
    },
    // 获取当前车密码
    getAdminCode(item) {
      let query = { devNum: item.unifyCode, imei: item.imei, devId: item.devId }
      // console.log('item2222',query);
      getAdminCode(query).then(res => {
        this.pwd.Code = res.data.Code;
      }).catch(err => {
        console.log('err', err)
      })
    },
    // 设置当前车密码
    setAdminCode() {
      let query = { ...{ devId: this.pwd.params.devId, devNum: this.pwd.params.unifyCode, imei: this.pwd.params.imei }, ...{ code: this.pwd.Code } }
      setAdminCode(query).then(res => {
        if (!res.result) {
          this.$message.info("设置密码成功！");
          this.vehicleStatus.modal.pwd = false;
        } else {
          this.$message.info(res.message);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 设置密码
    setPwd() {
      let str = "",
        arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
          'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
          'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
          'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
          '1', '2', '3', '4', '5', '6', '7', '8', '9',];
      for (let i = 0; i < 8; i++) {
        let pos = Math.round(Math.random() * (arr.length - 1));
        str += arr[pos];
      }
      this.pwd.Code = str;
    },
    // 查询安全员列表
    querySaferManag() {
      let querys = this.saferManagement.list.query;
      // 判断是否为点击页码查询
      getCount(querys).then((res) => {
        if (res.data.rowCount) {
          getTeaList(querys).then((res) => {
            this.getVehListInnerDev();
            this.$nextTick(() => {
              this.saferManagement.list.results = res.data;
            });
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          this.$nextTick(() => {
            this.getVehListInnerDev();
            this.saferManagement.list.results = [];
            this.saferManagement.list.total = 0;
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })

    },
    // 选择安全员
    selTea(e) {
      this.vehicleStatus.allocateVeh.allocate.newTeaId = e;
    },
    // 安全员
    handleChange(value) {
      this.saferSorce = this.saferManagement.list.results.filter(item => {
        if (item.name.indexOf(value) != -1) {
          return item;
        }
      }).map(item => {
        return `${item.name}/${item.phone}`
      })
      if (value.indexOf('/') != -1) {
        let newTeaId = this.saferManagement.list.results.filter(item => {
          if (value.substring(0, value.indexOf('/')) && value.substring(value.indexOf('/') + 1, value.length)) {
            return item.name === value.substring(0, value.indexOf('/')) && item.phone === value.substring(value.indexOf('/') + 1, value.length)
          } else if (value.substring(0, value.indexOf('/'))) {
            return item.name === value.substring(0, value.indexOf('/'))
          } else {
            return item.phone === value.substring(value.indexOf('/') + 1, value.length)
          }

        })
        this.vehicleStatus.allocateVeh.allocate.newTeaId = newTeaId[0].teaId;
      }
    },
    // 确认调配
    allocateVeh() {
      let stageL = this.vehicleStatus.seltable.stageParam.split('/');
      if (stageL.length <= 1) {
        this.$message.info('请确定科目');
        return;
      }
      if (this.vehicleStatus.allocateVeh.checkTea) {
        if (!this.vehicleStatus.allocateVeh.allocate.newTeaId) {
          this.$message.info('请先选择调配的安全员');
          return;
        }
      }
      let item = this.IsExsitveh();
      if (!item.length) {
        this.$message.info('此车号不存在，请重新输入');
        this.vehicleStatus.allocateVeh.allocate.newVehId = '';
        return;
      } else {
        if (stageL[1] === "科目3") {
          this.vehicleStatus.allocateVeh.allocate.newVehId = item.filter(ele => { return ele.stage === 3 })[0].vehId;
        } else {
          this.vehicleStatus.allocateVeh.allocate.newVehId = item.filter(ele => { return ele.stage === 2 })[0].vehId;
        }
      }
      if (this.vehicleStatus.allocateVeh.changeRrr.length) {
        this.vehicleStatus.allocateVeh.changeRrr.map(item => {
          // 没有返回teaId
          const query = { ...{ 'cId': item.cId, 'oldVehId': item.vehId, 'trainAdd': item.trainAdd, 'userId': sessionStorage.getItem('userId'), 'oldTeaId': item.teaId ? item.teaId : 0 }, ...this.vehicleStatus.allocateVeh.allocate }
          // console.log('query',query);
          allocateVeh(query).then(res => {
            if (!res.result) {
              this.$message.success('调配成功');
              this.vehicleStatus.allocateVeh.inCompleteArr = [];
              this.vehicleStatus.allocateVeh.changeRrr = [];
              this.vehicleStatus.allocateVeh.arr = [];
              this.vehicleStatus.allocateVeh.newVehNo = '';
              this.vehicleStatus.allocateVeh.allocate.newTeaId = 0;
              this.vehicleStatus.allocateVeh.checkTea = false;
              this.vehicleStatus.modal.deployvisible = false;
              this.carSorce = this.vehicleStatus.list.results.map(item => {
                return `${item.vehNo}号/科目${item.stage}`
              })
            } else {
              this.$message.error(res.message);
            }
          }).catch(err => {
            console.log('err', err);
          })
        })
      } else {
        this.$message.info('请选择需要调配的项，并且输入正确的时间');
      }
    },
    // 重置车辆选中信息
    restCarSel() {
      this.serchVehNo.arr.map(item => {
        if (this.$refs[`card${item}`]) {
          this.$refs[`card${item}`][0].$el.className = 'ant-card ant-card-bordered ant-card-hoverable';
        }
      });
      this.serchVehNo.stage3Arr.map(item => {
        if (this.$refs[`cardpr3${item}`]) {
          this.$refs[`cardpr3${item}`][0].$el.className = 'ant-card ant-card-bordered ant-card-hoverable';
        }
      })
    },
    // 搜索车号定位
    onSearch(val) {
      this.restCarSel();
      this.$nextTick(() => {
        if (this.$refs[`card${val}`]) {
          let top =document.getElementById(`card${val}`).offsetTop;
          $('.ant-layout-content').animate({scrollTop:top},300);
          this.$refs[`card${val}`][0].$el.className = 'ant-card ant-card-bordered ant-card-hoverable ant-cardBorder';
        }
      });
      this.serchVehNo.arr.push(val);

    },
    // 搜索车号定位
    onSearch2(val) {
      this.restCarSel();
      this.$nextTick(() => {
        if (this.$refs[`cardpr3${val}`]) {
          let top =document.getElementById(`cardpr3${val}`).offsetTop;
          $('.ant-layout-content').animate({scrollTop:top},300);
          this.$refs[`cardpr3${val}`][0].$el.className = 'ant-card ant-card-bordered ant-card-hoverable ant-cardBorder';
        }
      });
      this.serchVehNo.stage3Arr.push(val);
    },
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || $('.ant-layout-content').scrollTop();
      if(document.querySelector('#stage3')){
        let offsetTop = document.querySelector('#stage3').offsetTop - 52;
        scrollTop > offsetTop ? this.searchBarFixed = true : this.searchBarFixed = false
      }

     
    },
    // 获取车辆在线数
    getLastLocation() {
      let query = { unitId: sessionStorage.getItem('unitId') }
      getLastLocation(query).then(res => {
        if (res) {
          let obj = {};
          let total = 0;
          this.vehicleStatus.onlineResult = res.data.reduce(function (item, next) { obj[next.imei] ? '' : obj[next.imei] = true && item.push(next); return item; }, []);
          this.vehicleStatus.onlineResult.map(item => {
            if (item.online) {
              total = total + 1;
            }
          });
          this.vehicleStatus.onlineNum = total;
        }
        this.querySaferManag();
        this.changeRqData();
      });
    },
    descInput() {
      var txtVal = this.basicForm.getFieldValue('message').length;
      this.remnant = 50 - txtVal;
    },
    refreshStatus() {
      this.getLastLocation();
      this.isFav();
      this.btn.disabled = true;
      let timer1 = setInterval(() => {
        this.btn.number--;
        if (this.btn.number === 0) {
          this.btn.disabled = false;
          this.btn.number = 30;
          clearInterval(timer1);
        }
      }, 1000);
    }
  },
  created() { },
  mounted() {
    let params = this.$route.path.substr(1,)
    this.btnRole = this.getBtnRole(params);
    window.addEventListener('scroll',this.handleScroll, true);
    this.getLastLocation();
    this.timeOut.times = setInterval(this.getLastLocation, 900000);
    this.isFav();

  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.path !== '/vehicleStatus') {
          clearInterval(this.timeOut.times);
        } else {
          this.getLastLocation();
          this.timeOut.times = setInterval(this.getLastLocation, 900000);
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  activated() {
    this.isFav();
  },
};