import {
    PhotoAbnormalList,
    PhotoAbnormalListCount,
    PhotoByClassId,
    getPhotoByCId,
    setLocalTrainAbnormalReaded
} from "@/service/charge/index";
export default {
    //新增订单
    name: "businessFaceAnomaly",
    data() {
        return {
            //编辑页面数据
            businessFaceAnomaly: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 100,
                        orderDate: this.$moment().add(-1, "days").format('YYYYMMDD'),
                        orderDateEnd: this.$moment().format('YYYYMMDD'),
                        stage: 0,
                        unitId: sessionStorage.getItem('unitId'),
                        veh: '',
                        vehType: '',
                        userId: sessionStorage.getItem('userId'),
                        // 默认差未读
                        readTag: 0,
                    },
                    total: 0,
                    results: []
                },
                daterange: [this.$moment().add(-1, "days"), this.$moment()],
                imgView: {
                    visible: false,
                    urlbyCid: [],
                    urlbyClassId: []
                }
            },
            img: {
                previewVisible: false,
                previewImage: ''
            },
            //表头
            columns: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "业务编号",
                    dataIndex: "stageMark",
                },
                {
                    title: "业务类型",
                    dataIndex: "cType",
                    customRender: (item) => {
                        return this.$enums.cType[item];
                    }
                },
                {
                    title: "指派车辆",
                    dataIndex: "veh"
                }, {
                    title: "车号",
                    dataIndex: "vehNo"
                }, {
                    title: "科目",
                    dataIndex: "stage",
                    customRender: (item) => {
                        return `科目${item}`
                    }
                }, {
                    title: "车型",
                    dataIndex: "vehType",
                }, {
                    title: "安全员",
                    dataIndex: "coachName",
                    customRender: (item) => {
                        return item ? item : '暂无'
                    }
                }, {
                    title: "签到时间",
                    dataIndex: "trainBegin",
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        }
                        return this.translateDate(item);
                    }
                }, {
                    title: "签退时间",
                    dataIndex: "trainEnd",
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        }
                        return this.translateDate(item);
                    }
                }, {
                    title: "购买时长",
                    dataIndex: "costTime",
                    customRender: item => {
                        return `${item / 60} 小时`;
                    }
                }, {
                    title: "联系电话",
                    dataIndex: "phone"
                }, {
                    width: 75,
                    title: "照片",
                    align: "center",
                    scopedSlots: {
                        customRender: "photos"
                    }
                }, {
                    width: 75,
                    title: "状态",
                    align: "center",
                    scopedSlots: {
                        customRender: "option"
                    }
                },
            ],
            excelName: ['业务人脸对比异常'],
            selName: [],
            excelData: [],
        };
    },
    methods: {
        queryTraining(queryBy) {
            let querys = this.businessFaceAnomaly.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            PhotoAbnormalListCount(querys).then(res => {
                this.businessFaceAnomaly.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    PhotoAbnormalList(querys).then(res => {
                        this.$nextTick(() => {
                            this.businessFaceAnomaly.list.results = res.data;
                        });
                    });
                } else {
                    this.$nextTick(() => {
                        this.businessFaceAnomaly.list.results = [];
                        this.businessFaceAnomaly.list.total = 0;
                        this.resetExport();
                        let excolumns=this.columns.filter(item =>{
                            return item.title!='照片'
                         })
                        this.selName = [`查询日期: ${this.businessFaceAnomaly.list.query.begin}-${this.businessFaceAnomaly.list.query.end}`, `科目: ${this.$rformat.methods.r_projectItem(this.businessFaceAnomaly.list.query.stage)}`, `车型：${this.$rformat.methods.r_CarType(this.businessFaceAnomaly.list.query.vehType)}`, `是否查阅：${this.$rformat.methods.r_readTag(this.businessFaceAnomaly.list.query.readTag)}`];
                        for (let i = 0; i < excolumns.length - 5; i++) {
                            this.selName.push(" ")
                        }
                        let excelsource = this.comexport(excolumns, this.businessFaceAnomaly.list.results);
                        excelsource = this.exportDate(excelsource);
                        this.excelData = [... this.excelData, ...excelsource];
                    });
                }
            });
        },
        getDate(){
            this.resetExport();
            let querys = this.businessFaceAnomaly.list.query;
            let excolumns=this.columns.filter(item =>{
                return item.title!='照片'
             })
             let serch ={...querys};
            serch.pageIndex = 1;
             serch.pageSize = this.businessFaceAnomaly.list.total + 100;
             PhotoAbnormalList(serch).then(res =>{
                this.selName = [`查询日期: ${this.businessFaceAnomaly.list.query.orderDate}-${this.businessFaceAnomaly.list.query.orderDateEnd}`, `科目: ${this.$rformat.methods.r_projectItem(this.businessFaceAnomaly.list.query.stage)}`, `车型：${this.$rformat.methods.r_CarType(this.businessFaceAnomaly.list.query.vehType)}`, `是否查阅：${this.$rformat.methods.r_readTag(this.businessFaceAnomaly.list.query.readTag)}`];
                for (let i = 0; i < excolumns.length - 5; i++) {
                    this.selName.push(" ")
                }

                let excelsource = this.comexport(excolumns, res.data);
                excelsource = this.exportDate(excelsource);
                this.excelData = [... this.excelData, ...excelsource];
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
             })
        },
        // 关键字查询
        onSearch(e) {
            this.businessFaceAnomaly.list.query.readTag = e;
        },
        // 时间变化
        dateChange(e) {
            this.businessFaceAnomaly.daterange = e;
            this.businessFaceAnomaly.list.query.orderDate = this.$moment(e[0]).format('YYYYMMDD');
            this.businessFaceAnomaly.list.query.orderDateEnd = this.$moment(e[1]).format('YYYYMMDD')
        },
        // 选择科目
        selectStage(e) {
            this.businessFaceAnomaly.list.query.stage = e;
        },
        // 选择车型
        selectVeh(e) {
            this.businessFaceAnomaly.list.query.vehType = e;
        },
        // 查询照片
        viewPhoto(record) {
            PhotoByClassId({ classId: record.classId }).then(res => {
                this.businessFaceAnomaly.imgView.urlbyClassId = res.data.map(item => {
                    let time = this.$moment(item.photoTime).format('YYYYMMDDHHmmss');
                    return { ...item, ...{ timer: time } }
                });
                this.businessFaceAnomaly.imgView.urlbyClassId.sort(this.compareKey());
                getPhotoByCId({ cId: record.cId }).then(res => {
                    this.businessFaceAnomaly.imgView.urlbyCid = res.data;
                    this.businessFaceAnomaly.imgView.visible = true;
                })
            })
        },
        compareKey() {
            return function (obj1, obj2) {
                if (obj1["timer"] < obj2["timer"]) {
                    return -1;
                } else if (obj1["timer"] === obj2["timer"]) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },
        // 已处理
        option(record) {
            setLocalTrainAbnormalReaded({ classId: record.classId }).then(res => {
                if (!res.message) {
                    this.$message.info('数据已处理!');
                    this.queryTraining();
                }
            })
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        // 图片放大查看
        viewImg(img) {
            this.img.previewImage = img;
            this.img.previewVisible = true;
        },
        disabledDate(current) {
            let cur=this.$moment(current).format('YYYYMMDD');
            let monthago=this.$moment().subtract(6,'months').format('YYYYMMDD');
            if(monthago <= cur){
                return false
            }else{
                return true
            }
        },

    },
    watch: {},
    created() { },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.queryTraining();
        //     this.isFav();
        //   }
        this.queryTraining();
        this.isFav();
    },
    activated() {
        // this.queryTraining();
        this.isFav();
    },
};