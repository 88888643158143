import {
    getTrainList,
    getListCount,
    getAllocateLog,
    getTrainPhoto,
    modifyStu
} from "@/service/charge/index";
export default {
    //新增订单
    name: "trainingDetails",
    data() {
        return {
            //编辑页面数据
            trainingDetails: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 10,
                        orderDate: this.$moment().format('YYYYMMDD'),
                        orderDateEnd: this.$moment().format('YYYYMMDD'),
                        stage: 0,
                        unitId: sessionStorage.getItem('unitId'),
                        veh: '',
                        vehType: '',
                        stageMark: 0,
                        cType: '',
                        coachName: '',
                        userId: sessionStorage.getItem('userId'),
                        vehNo: 0,
                    },
                    total: 0,
                    results: [],
                    resultsLog: []
                },
                AllocateLogVisible: false,
                daterange: [],
            },
            vueAmaps: {
                zoom: 13,
                center: [104.762406, 31.420331],
                vdata: [],//车辆定位数据
                vmarkers: [],//车辆定位
                infoWindow: {},//信息窗体
                cluster: null,//点聚合
                texts: [],
                currentWindow: [],
                window: {}
            },
            advancequry: {},
            stageMarkDeatil: {
                visible: false,
                stuMsg: {},
                data: {},
                trainPhotos: [],
                checkPhotos: [],
                previewImage: '',
                previewVisible: false,
                tabindex: "1",
                isSearch: false
            },
            userId: sessionStorage.getItem('userId'),
            //车辆调配日志表头
            columnsLog: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "原车号",
                    dataIndex: "VehNo",
                },
                {
                    title: "原车牌号",
                    dataIndex: "Veh",
                },
                {
                    title: "原安全员",
                    dataIndex: "Name",
                },
                {
                    title: "调入车号",
                    dataIndex: "newVehNo",
                },
                {
                    title: "调入车牌号",
                    dataIndex: "newVeh",
                },
                {
                    title: "新安全员",
                    dataIndex: "newName",
                    customRender: item => {
                        return item ? item : '未调配'
                    }
                },
                {
                    title: "增加时间(分钟)",
                    dataIndex: "trainAdd",
                    customRender: item => {
                        return `${item/60}分钟`
                    }
                },
                {
                    title: "调配操作员",
                    dataIndex: "NickName",
                },
                {
                    title: "调配时间",
                    dataIndex: "inTime",
                    customRender: item => {
                        return this.transForm(item);
                    }
                },
            ],
            //表头
            columns: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "业务编号",
                    dataIndex: "stageMark",
                    scopedSlots: {
                        customRender: "stageMark"
                    }
                },
                {
                    title: "指派车辆",
                    dataIndex: "veh"
                }, {
                    title: "车号",
                    dataIndex: "vehNo",
                    customRender: (item) => {
                        return `${item}号`
                    }
                }, {
                    title: "科目",
                    dataIndex: "stage",
                    customRender: (item) => {
                        return `${item}`
                    }
                }, {
                    title: "车型",
                    dataIndex: "vehType",
                }, {
                    title: "安全员",
                    dataIndex: "coachName",
                    customRender: (item) => {
                        return item ? item : '暂无'
                    }
                }, {
                    title: "签到时间",
                    dataIndex: "trainBegin",
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        } else {
                            if (typeof item === 'number') {
                                return this.translateDate(item);
                            }
                        }
                    }
                }, {
                    title: "签退时间",
                    dataIndex: "trainEnd",
                    customRender: item => {
                        if (!item) {
                            return '暂无';
                        } else {
                            if (typeof item === 'number') {
                                return this.translateDate(item);
                            }
                        }
                    }
                }, {
                    title: "购买时长",
                    dataIndex: "costTime",
                    customRender: item => {
                        return `${item / 60} 小时`;
                    }
                }, {
                    title: "联系电话",
                    dataIndex: "phone"
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ]
        };
    },
    methods: {
        async queryTraining(queryBy) {
            let querys = this.trainingDetails.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            await getListCount(querys).then(res => {
                this.trainingDetails.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    getTrainList(querys).then(res => {
                        this.$nextTick(() => {
                            this.trainingDetails.list.results = res.data;
                        });
                    });
                } else {
                    this.$nextTick(() => {
                        this.trainingDetails.list.results = [];
                        this.trainingDetails.list.total = 0;
                    });
                }
            });
        },
        // 关键字查询
        onSearchh(e) {
            this.trainingDetails.list.query.vehNo = e.target.value ? e.target.value : 0;
        },
        onSearch(e) {
            this.trainingDetails.list.query.stageMark = e.target.value ? e.target.value : 0;
        },
        // 表格行颜色样式
        trColor(record) {
            //end-row 结算 bul-row 正在培训
            let className = 'light-row';
            if (record.trainBegin < record.trainEnd) { className = 'end-row' }
            else if (record.trainBegin > record.trainEnd) { className = 'bul-row' }
            return className;
        },
        //调配日志
        AllocateLog(record) {
            let querys = { cId: record.cId }
            getAllocateLog(querys).then(res => {
                this.trainingDetails.list.resultsLog = [...res.data]
            })
            this.trainingDetails.AllocateLogVisible = true;
        },
       async resetserach() {
            this.setQueryByAdvanced(this.advancequry);
        },
        // 查看详情
        viewDetail(record) {
            let querys = { ...this.trainingDetails.list.query };
            querys.stageMark = record.stageMark;
            this.stageMarkDeatil.data = record;
            getTrainList(querys).then(res => {
                if (res.data.length) {
                    this.stageMarkDeatil.data.costTime = res.data[0].costTime ? res.data[0].costTime : 0;
                    this.stageMarkDeatil.data.imei = res.data[0].imei ? res.data[0].imei : '';
                    this.stageMarkDeatil.data.newImei = res.data[0].newImei ? res.data[0].newImei : '';
                }
                getTrainPhoto({ cId: record.cId, unitId: sessionStorage.getItem('unitId') }).then(res => {
                    this.stageMarkDeatil.trainPhotos = res.data;
                    this.stageMarkDeatil.checkPhotos = res.data;
                    modifyStu({ cId: record.cId }).then(res => {
                        this.stageMarkDeatil.stuMsg = res.data[0];
                        this.stageMarkDeatil.visible = true;
                    })
                })
            })
        },
        closeModel() {
            this.stageMarkDeatil.visible = false;
            this.stageMarkDeatil.tabindex = '1';
            this.stageMarkDeatil.isSearch = false;
            this.vueAmaps.markers = [];
            this.stageMarkDeatil.trainPhotos = []
        },
        tabChange(val) {
            this.stageMarkDeatil.tabindex = val;
        },
        formatDateTime(val) {
            let date = val.split('T');
            let dateMonth = date[0];
            let min = date[1].substr(0, date[1].indexOf('.'));
            return dateMonth + ' ' + min;
        },
        async getTrainList() {
            await this.queryTraining();
            await this.isFav();
            await this.resetserach();
        },
        // 图片放大查看
        viewImg(img) {
            this.stageMarkDeatil.previewImage = img;
            this.stageMarkDeatil.previewVisible = true;
        },
    },
    watch: {},
    created() { },
    mounted() {
        this.advancequry = { ...this.trainingDetails.list.query };
        this.getTrainList();
    },
    activated() {
        // this.getTrainList();
    }
};
