var echarts = require('echarts');
import {
    getTrainByVehCostSum,
    trainByDayDetail
} from "@/service/businessData/index";
export default {
    name: "carRental",
    data() {
        return {
            carRental: {
                list: {
                    query: {
                        begin: this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        localTags: 0,
                        stage: 2,
                        unitId:sessionStorage.getItem('unitId'),
                        vehType:"",
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: [],
                    vehNoArr:[],
                    totalTime:[],
                },
                datesingle: this.$moment(),
                columns: [],
                option: {
                    xAxis:[],
                    series:[]
                }
            },
            excelName: ['租车情况'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId'),
            tableHeigh:{y:410},
            tableWid:{
              startL:0,
              endL:0
            },
            activeKey:['1']
        }
    },
    methods: {
        queryShortData() {
            let querys = this.carRental.list.query;
            this.carRental.list.results=[];
            this.carRental.list.totalTime=[];
            this.carRental.columns=[];
            this.carRental.option.xAxis=[];
            this.carRental.option.series=[];
            let obj={turnCnt:'total'}
            getTrainByVehCostSum(querys).then(res => {
                let cw=(document.body.clientWidth||document.documentElement.clientWidth)-160;
                this.tableWid.startL=res.data.length;
                this.carRental.columns.push({
                    title: "",
                    dataIndex: "turnCnt",
                    width:90,
                    align:"center",
                    customRender: (item) => {
                        if(item==="date"){
                            return "起止时间";
                        }else if(item==="phone"){
                            return "VIP联系电话";
                        }else if(item==="total"){
                            return "合计时间";
                        }
                        else{
                            return `第${item}轮`;
                        }
                      }
                });
                for(var value of res.data){
                    obj={...obj,...{[value.vehNo]:value.costTime?`${value.costTime/60}小时`:''}}
                    this.carRental.option.xAxis.push(`${value.vehNo}号`);
                    this.carRental.option.series.push(value.costTime/60);
                    this.carRental.list.vehNoArr.push(value.vehNo);
                    if(value.costTime>0){
                        this.carRental.columns.push({
                            title:`${value.vehNo}号`,
                            dataIndex:value.vehNo,
                            width:95,
                            align:"center",
                        })
                    }
                 else {
                        this.carRental.columns.push({
                            title:`${value.vehNo}号`,
                            dataIndex:value.vehNo,
                            width:52,
                            align:"center",
                        })
                    }
                }
                this.carRental.list.totalTime.push(obj)
                this.carRental.columns.push({
                    title:``,
                    dataIndex:'',
                });
                this.tableWid.endL==this.carRental.option.series.filter((item)=>{
                    return item;
                })
                if((this.tableWid.startL+2)*95>=cw){
                    this.tableHeigh.x=(this.tableWid.endL+2)*95+(this.tableWid.startL-this.tableWid.endL)*95;
                }else{
                    this.tableHeigh={y:this.tableHeigh.y}
                }
                this.drawLine();
                trainByDayDetail(querys).then(res => {
                    this.resetExport();
                    for(var item of res.data){
                        this.carRental.list.results.push(
                        {turnCnt:item.turnCnt,id:item.turnCnt,[item.vehNo]:`${item.costTime/60}小时`},
                        {turnCnt:'date',id:item.turnCnt,[item.vehNo]:`${!item.trainBegin&&!item.trainEnd?``:`${this.transDate(item.trainBegin)}-${this.transDate(item.trainEnd)}`}`},
                        {turnCnt:'phone',id:item.turnCnt,[item.vehNo]:item.phone})
                    }
                    this.carRental.list.results=this.contactObj(this.carRental.list.results);
                    this.carRental.list.results.push(this.carRental.list.totalTime[0]);
                    this.carRental.list.results=this.carRental.list.results.map((item,index)=>{
                       return {...item,...{reindex:index}}
                    });
                    this.selName = [`查询日期: ${this.carRental.list.query.begin}-${this.carRental.list.query.end}`, `归属: ${this.$rformat.methods.r_isSch(this.carRental.list.query.localTags)}`, `科目: ${this.$rformat.methods.r_projectItem(this.carRental.list.query.stage)}`,`车型：${this.$rformat.methods.r_CarType(this.carRental.list.query.vehType)}`];
                    for(let i=0;i<this.carRental.columns.length-4;i++){
                        this.selName.push(" ")
                    }
                    let excelsource= this.comexport(this.carRental.columns,this.carRental.list.results)
                        excelsource=this.exportDate(excelsource);
                     this.excelData=[... this.excelData,...excelsource];
                   
                })
            })
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        // 转换时间
        transDate(item){
            if(!item){
                return '暂无';
            }
            var unixTimestamp = new Date(item * 1000);
            // let commonTime=this.$moment(unixTimestamp).format('hh:mm');
            // let commonTime = unixTimestamp.toLocaleString('chinese', { hour12: false });   //转换为24小时制的时间格式
            let h = (unixTimestamp.getHours()<10) ? "0"+unixTimestamp.getHours() : unixTimestamp.getHours();
            let min = (unixTimestamp.getMinutes()<10) ? "0"+unixTimestamp.getMinutes() : unixTimestamp.getMinutes();
            let commonTime=h+':'+min;
            return commonTime;
        },
        // 合并对象
        contactObj(list){
            let result = []
            let cache = {}
            list.forEach((item,i) => {
                let key = `id:${item.id},turnCnt${item.turnCnt}`;
                let index = cache[key];
                if (index !== undefined) {
                  let vehId = this.getKey(item);
                  result[index][vehId[0]] = item[vehId[0]];
                } else {
                  result.push(Object.assign({}, item))
                  cache[key] = result.length - 1;
                }
              });
              return result  
        },
       //获取对象名
        getKey(item) {
          return Object.keys(item).filter(item => {
          return item != 'id' && item != 'turnCnt'
        })
        },
        // 修改表格颜色
        trColor(record, index){
            let className ='endd-row';
            if(record.turnCnt!=='date'&&record.turnCnt!=='phone'&&record.turnCnt!=='total'){
              return className;
            }
            if(record.turnCnt==='total'){
                className='red-row';
                return className;
            }
    
        },
        dataSel(date, dateString){
            this.carRental.list.query.begin=date.format("YYYYMMDD");
            this.carRental.list.query.end=date.format("YYYYMMDD");
        },
        exportDate(excelsource){
            excelsource = excelsource.map(item => {
                let arr = item;
                if (item[0]) {
                    arr = item.slice(1);
                } else {
                    arr = item;
                }
                return arr.map((e, index) => {

                    if (e&&!e.length) {
                        return e = `第${e}轮`
                    }
                    if (e === "date") {
                        return e = "起止时间"
                    }
                    if (e === "phone") {
                        return e = "联系电话"
                    }
                    if (e === "total") {
                        return e = "合计时间"
                    } else {
                        if (!e) {
                            return " "
                        }
                        return e;
                    }
                })
            });
            return excelsource;
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        },
        drawLine() {
            // 初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('main'))
            myChart.showLoading();
            window.onresize = myChart.resize;
            // 绘制图表
            myChart.setOption({
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        magicType: {
                            show: true,
                            type: ['pie', 'funnel']
                        },
                        saveAsImage: { show: true },
                        saveAsImage: { show: true },
                        magicType: { show: true, type: ['line', 'bar'] }
                    }
                },
                title: { text: '租车情况' },
                tooltip: {},
                xAxis: {
                    axisLabel: {
                        interval: 0,
                        rotate: 38
                    },
                    data:this.carRental.option.xAxis

                },
                yAxis: {},
                series: [{
                    name: '销量',
                    type: 'bar',
                    data: this.carRental.option.series,
                    itemStyle: {
                            normal: {
                            color: '#40a9ff',
                            label: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: 'red',
                                    fontSize: 16
                                }
                            }
                        }
                    }
                }]

            });
            myChart.hideLoading();
        },
        collapseChange(key) {
            if (!key) {
              this.tableHeigh.y =document.documentElement.clientHeight - 280;
              $('.tableFa').css({height:document.documentElement.clientHeight - 240 +'px'})
              $('#collapse').css({
                position: 'absolute',
                bottom: '0px',
                width: '140px',
              });
              $('#collapse i').css({
                color: '#fff'
              });
              $('#collapse span').css({
                color: '#fff'
              });
              $('.ant-collapse-item').css({
                background: '#36C5C3',
              });
            } else {
             $('.tableFa').css({height:'465px'})
              this.tableHeigh.y = 410
              $('#collapse').css({
                position: 'relative',
                width: '100%',
              });
              $('#collapse i').css({
                color: '#000'
              });
              $('#collapse span').css({
                color: '#000'
              });
              $('.ant-collapse-item').css({
                background: '#fff',
              });
            }
          },
    },
    created() { 
    },
    mounted() {
        this.queryShortData();
        this.isFav();
        this.drawLine();
        // if(!this.$route.meta.keepAlive){
        //     this.queryShortData();
        //     this.isFav();
        //     this.drawLine();
        //   }
    },
    activated(){
        this.isFav();
        // this.queryShortData();
        this.drawLine();
    }
};