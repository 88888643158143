const stuTrain = resolve => require(["@/views/businessData/localSchoolTraining/stuTrain/index.vue"], resolve);
const teaTrain = resolve => require(["@/views/businessData/localSchoolTraining/teaTrain/index.vue"], resolve);
const carTrain = resolve => require(["@/views/businessData/localSchoolTraining/carTrain/index.vue"], resolve);
export default {
    //新增订单
    name: "localSchoolTraining",
    data() {
        return {
        }
    },
    methods: {
    },
    watch: {},
    created() {
    },
    components: {
        stuTrain,
        teaTrain,
        carTrain
    },
    mounted() {

    },
    activated(){
    }
};