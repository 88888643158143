import {getInfo,addInfo,delInfo} from "@/service/systemSetup/index";
export default {
  //新增订单
  name: "driverSchool",
  data() {
    return {
      //编辑页面数据
      driverSchool: {
        list: {
          query: {
            unitId:sessionStorage.getItem('unitId'),
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: []
        },
        pwdTitle:'驾校字典表填写',
        pwdTrain:false,
      },
      userId:sessionStorage.getItem('userId'),
      basicForm: this.$form.createForm(this),
      remnant:32,
      //表头
      columns: [
        {
          width: 75,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        },
        {
          width:100,
          title: "全称",
          dataIndex: "schName",
        },{
          width:100,
          title: "简称",
          dataIndex: "shortName",
        },{
          width:100,
          title: "是否本校",
          dataIndex: "localTags",
          customRender:(item)=>{
           return item?'本校':'非本校'
          }
        },
        {
          width:75,
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['驾校管理'],
      selName:[],
      excelData:[],
    };
  },
  methods: {
    // 查询列表
    querySaferdriver() {
      let querys = this.driverSchool.list.query;

      getInfo(querys).then((res) => {
        this.resetExport();
         this.driverSchool.list.results=[...res.data];
         for(let i=0;i<this.columns.length-1;i++){
          this.selName.push(" ")
        }
        let excelsource = this.comexport(this.columns, this.driverSchool.list.results);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$store.dispatch("StoreDataQuery/setQueryByStarCost",querys);
          this.$store.dispatch("StoreDataQuery/setQueryBySchool",querys)
      })
     
    },
    getDate(){
      this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
},
    // 删除
    delData(record){
      delInfo(record).then((res)=>{
        if(res.message.length){
          this.$message.error(res.message)
        }else{
          this.querySaferdriver();
          this.$message.success('删除成功');
        }
      });
    },
    // 新增
    addSchool(){
      let querys = this.driverSchool.list.query;
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          values.unitId=querys.unitId;
          addInfo(values).then((res)=>{
            if(res.message.length){
              this.$message.error(res.message)
            }else{
              this.resetInfo();
              this.$message.success('新增成功');
            }
          });
        }
      });
    },
    // 重置model
    resetInfo(){
      this.querySaferdriver();
      this.driverSchool.pwdTrain=false;
      this.handleFormFieldsReset(this.basicForm);
      this.remnant=32;
    },
    resetExport(){
      this.selName=[];
      this.excelData=[];
    },
    descInput(){
      var txtVal = this.basicForm.getFieldValue('note').length;
      this.remnant =32 - txtVal;
    },
  },
  watch: {
  },
  created() {},
  mounted() {
    this.querySaferdriver();
     this.isFav();
  },
  activated(){
     this.isFav();
  }
};