import {
    getOrderSetList,
    modifyOrderSet,
    addOrderDenySet,
    delOrderDenySet,
    getOrderDenySetList
} from "@/service/appointment/index";
export default {
    //新增订单
    name: "dateLocking",
    data() {
        return {
            //编辑页面数据
            dateLocking: {
                list: {
                    query: {
                        unitId:sessionStorage.getItem('unitId'),
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: []
                },
                daterange: [],
                showWeixin: '',
                showAndriod: '',
                showIOS: '',
                showNet: '',
            },
            // basicForm: this.$form.createForm(this),
          dateForm: this.$form.createForm(this),
            //表头
            columns: [{
                width: 75,
                title: "序号",
                align: "center",
                scopedSlots: {
                    customRender: "SerialNumber"
                }
            }, {
                title: "日期起",
                dataIndex: "denyDateBegin",
                customRender:(item)=>{
                    const el=String(item);
                    return this.$moment(el).format('YYYY-MM-DD');
                }
            },
                {
                    title: "日期止",
                    dataIndex: "denyDateEnd",
                    customRender:(item)=>{
                        const el=String(item);
                        return this.$moment(el).format('YYYY-MM-DD');
                    }
                },
                {
                    title: "情况备注",
                    dataIndex: "note",
                    customRender:(item)=>{
                        return item?item:'暂无备注'
                    }
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ],
            remnant: 32,
        };
    },
    methods: {
        // // 获取预约锁定配置列表
        getOrderDenySetList() {
            this.dateLocking.daterange=[];
            let querys = this.dateLocking.list.query;
            getOrderDenySetList(querys).then((res) => {
                // this.getOrderSetList();
                this.dateLocking.list.results=[...res.data];
                res.data.map((item)=>{
                    this.dateLocking.daterange.push({denyDateBegin:item.denyDateBegin,denyDateEnd:item.denyDateEnd})
                })
            })
        },
        // 解锁
        resizeData(record) {
            // console.log(record)
            const {unitId,rowId} = record;
            let params = {unitId,rowId}
            delOrderDenySet(params).then(res=>{
                if (res.message.length) {
                    this.$message.error(res.message)
                } else {
                    this.resetInfo(true);
                    this.$message.success('解锁成功');
                }
            })
        },
        // 保存设置
        // saveSeting() {
        //     this.basicForm.validateFields((err, values) => {
        //         if (!err) {
        //             // values.showAndriod = values.showAndriod ? 0 : 1;
        //             // values.showIOS = values.showIOS ? 0 : 1;
        //             values.showNet = values.showNet ? 0 : 1;
        //             values.showWeixin = values.showWeixin ? 0 : 1;
        //             values.unitId = this.parameterSettings.list.query.unitId;
        //             // console.log('values',values)
        //             modifyOrderSet(values).then((res) => {
        //                 if (res.message.length) {
        //                     this.$message.error(res.message)
        //                 } else {
        //                     this.resetInfo(false);
        //                     this.$message.success('保存成功!');
        //                 }
        //             })
        //         }
        //     });
        // },
        // 获取预约全局参数设置列表
        // getOrderSetList() {
        //     let querys = this.parameterSettings.list.query;
        //     getOrderSetList(querys).then((res) => {
        //         // console.log('res',res);
        //         if (res.data && res.data.length) {
        //             this.$nextTick(()=>{
        //                 const showDays = res.data[0].showDays
        //                 // const showAndriod = res.data[0].showAndriod ? false : true;
        //                 // const showIOS = res.data[0].showIOS ?  false : true;
        //                 const showNet = res.data[0].showNet ?  false : true;
        //                 const showWeixin = res.data[0].showWeixin ?  false : true;
        //                 // this.settingVal(showDays, showAndriod, showIOS, showNet, showWeixin);
        //                 this.settingVal(showDays,showNet, showWeixin)
        //             })
        //         } else {
        //             this.settingVal(1, true, true, true, true)
        //         }
        //     })
        // },
        // 重置信息
        resetInfo(getOrder) {
            // true 执行getOrderDenySetList,false执行 getOrderSetList
            if(getOrder){
                this.getOrderDenySetList();
            }else{
                this.getOrderSetList();
            }
        },
        // 设置值 showAndriod, showIOS,
        // settingVal(showDays, showNet, showWeixin) {
        //     // console.log('showDays, showAndriod, showIOS, showNet, showWeixin',showDays, showAndriod, showIOS, showNet, showWeixin)
        //     this.$nextTick(() => {
        //         // this.appointment.showAndriod = showAndriod;
        //         // this.appointment.showIOS = showIOS;
        //         this.dateLocking.showNet = showNet;
        //         this.dateLocking.showWeixin = showWeixin;
        //         this.basicForm.setFieldsValue({
        //             showDays: showDays,
        //             // showAndriod: showAndriod,
        //             // showIOS: showIOS,
        //             showNet: showNet,
        //             showWeixin: showWeixin,
        //         });
        //     })
        // },
        // 新增预约锁定配置日期
        addOrderDenySets(){
            this.dateForm.validateFields((err, values) => {
                if (!err) {
                    values.denyDateBegin = this.$moment(values.datarang[0]).format('YYYYMMDD');
                    values.denyDateEnd = this.$moment(values.datarang[1]).format('YYYYMMDD');
                    values.unitId = this.dateLocking.list.query.unitId;
                    values.status = 0
                    values.userId =sessionStorage.getItem('userId');
                    const { denyDateBegin,note,denyDateEnd,unitId,status,userId} = values;
                    let params = {denyDateBegin,note, denyDateEnd,unitId,status,userId}
                    let dateval= this.dateLocking.daterange.map((item)=>{
                        return  Number(params.denyDateBegin)<item.denyDateBegin&&item.denyDateEnd<=Number(params.denyDateEnd);
                    })
                    if(dateval.includes(true)){
                        this.$message.info('区间内含有已选日期，请重新选择');
                        return ;
                    }
                    addOrderDenySet(params).then((res) => {
                        if (res.message.length) {
                            this.$message.error(res.message)
                        } else {
                            this.resetInfo(true);
                            this.$message.success('新增成功');
                            this.handleFormFieldsReset(this.dateForm);
                            this.remnant=32;
                        }
                    })
                }
            });
        },
        // 数据区间置灰
        disabledDate(current) {
            let cur=this.$moment(current).format('YYYYMMDD');
            let value= this.dateLocking.daterange.map((item)=>{
                return  (Number(cur) && item.denyDateBegin<=Number(cur)&&Number(cur)<=item.denyDateEnd)||(cur <Number(this.$moment().endOf('day').format('YYYYMMDD')));
            })
            return value.includes(true);
        },
        descInput(){
            var txtVal = this.dateForm.getFieldValue('note').length;
            this.remnant =32 - txtVal;
        },
    },
    watch: {},
    created() {},
    mounted() {
        this.getOrderDenySetList();
        this.isFav();
    },
    activated(){
        this.isFav();
    }
};